import { api_user } from "@/shared/api";
import { defineStore } from "pinia";
import { computed, ref } from "vue";
import { useApi } from "@/shared/api";
import { readonlyStatuses, currencies, graphCurrencies } from "@/shared/config/constants";

export const useUserStore = defineStore("user", () => {
    const user = ref({ roles: [], hasOfficeRole: false });
    const company = computed(() => user.value?.company);

    const currency = computed(() => currencies[company.value?.default_currency || 1]);
    const graphCurrency = computed(() => graphCurrencies[currency.value]);

    /**
     * Returns an object indicating whether the user's subscription is on trial or has ended.
     *
     * @return {Object} An object with the following properties:
     *   - `isTrial`: A boolean indicating whether the user's subscription is on trial.
     *   - `days`: An integer indicating the number of days left on the user's trial, or 0 if the subscription has ended.
     *             This property is only present if `isTrial` is true.
     */
    const trial = computed(() => {
        let data = { isTrial: false };
        const status = company.value?.status;

        const isSubscriptionEnd = readonlyStatuses.includes(status);
        const isTrial = status === "TRIAL";

        if (isTrial || isSubscriptionEnd) {
            data = { isTrial: true, days: isSubscriptionEnd ? 0 : user.value.trialDaysLeft };
        }

        return data;
    });

    /**
     * Sets the user object by making an asynchronous API call to retrieve user data.
     * If the API call is successful, the user object is updated with the retrieved data.
     * Additionally, it checks if the user has the "office" or "invoice" role and updates the corresponding properties.
     *
     * @return {Promise<void>} A Promise that resolves when the user object has been updated.
     */
    const setUser = async () => {
        user.value = (await useApi.get({ link: api_user() })) || {};
        user.value.hasOfficeRole = !!user.value.roles?.some((el) => el === "office");
        user.value.hasInvoiceRole = !!user.value.roles?.some((el) => el === "invoice");
    };

    return { user, company, trial, currency, graphCurrency, setUser };
});
