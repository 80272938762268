import { localization } from "@/shared/lib";

const jobs = {
    title: localization("records.table_titles.jobs"),
    key: "jobs",
    columns: [
        {
            id: 1,
            text: localization("records.fields.job_no"),
            field: "jobno",
            width: "90px",
        },
        {
            id: 2,
            text: localization("records.fields.property"),
            field: "job_address",
        },
        {
            id: 3,
            text: localization("records.fields.customer"),
            field: "customer",
        },
        {
            id: 4,
            text: localization("records.fields.priority"),
            field: "priority_title",
        },
        {
            id: 5,
            text: localization("records.fields.description"),
            field: "description",
        },
        {
            id: 5,
            text: localization("records.fields.job_due_date"),
            field: "due_date",
            date: "short",
        },
        {
            id: 5,
            text: localization("records.fields.modified_date"),
            field: "modified",
            date: "short",
        },
    ],
};

const property = {
    title: localization("records.table_titles.property"),
    key: "property",
    columns: [
        {
            id: 1,
            text: localization("records.fields.property"),
            field: "property",
            width: "150%",
        },
        //{
        //    id: 2,
        //    text: localization("records.fields.customer"),
        //    field: "customer",
        //},
        {
            id: 3,
            text: localization("records.fields.gas_service_due"),
            field: "gsexpiry",
            date: "short",
        },
        {
            id: 4,
            text: localization("records.fields.modified_date"),
            field: "modified",
            date: "short",
        },
    ],
};

const invoices = {
    title: localization("records.table_titles.invoices"),
    key: "invoices",
    subtotal: ["outstanding"],
    columns: [
        {
            id: 1,
            text: localization("records.fields.invoice_date"),
            field: "invoice_date",
            date: "short",
        },
        {
            id: 2,
            text: localization("records.fields.job_no"),
            field: "job_number",
            width: "90px",
        },
        {
            id: 3,
            text: localization("records.fields.invoice_no"),
            field: "invoiceno",
            width: "90px",
        },
        {
            id: 4,
            text: localization("records.fields.outstanding"),
            field: "outstanding",
            type: "number",
            currency: true,
        },
        {
            id: 5,
            text: localization("records.fields.paid"),
            field: "paid",
            type: "number",
            currency: true,
        },
        {
            id: 6,
            text: localization("records.fields.reconciled"),
            field: "reconciled",
        },
        {
            id: 7,
            text: localization("records.fields.days"),
            field: "days",
            type: "number",
        },
        {
            id: 9,
            text: localization("records.fields.property"),
            field: "job_address",
        },
        {
            id: 8,
            text: localization("records.fields.customer"),
            field: "customer",
        },
        {
            id: 10,
            text: localization("records.fields.job_description"),
            field: "job_description",
        },
        {
            id: 11,
            text: localization("records.fields.add_payment"),
            customLink: true,
            field: "add_payment",
            width: "120px",
        },
    ],
};

const outstanding_invoices = {
    title: localization("records.table_titles.outstanding_invoices"),
    key: "outstanding_invoices",
    subtotal: ["outstanding"],
    columns: [...invoices.columns].filter((el) => el.id !== 6),
};

const paid_invoices = {
    title: localization("records.table_titles.paid_invoices"),
    key: "paid_invoices",
    subtotal: ["paid"],
    columns: [...invoices.columns].filter((el) => el.id !== 11),
};

const history = {
    title: localization("records.table_titles.history"),
    key: "history",
    columns: [
        {
            id: 1,
            text: localization("records.fields.date"),
            field: "date",
            date: "short",
        },
        {
            id: 2,
            text: localization("records.fields.modified_by"),
            field: "modified_by",
        },
        {
            id: 3,
            text: localization("records.fields.details"),
            field: "details",
            width: "250%",
        },
        {
            id: 4,
            text: localization("records.fields.property"),
            field: "job_address",
        },
        {
            id: 5,
            text: localization("records.fields.job"),
            field: "job",
        },
        {
            id: 6,
            field: "icon",
            text: " ",
            width: "50px",
        },
    ],
};

const gas_appliances = {
    title: localization("records.table_titles.gas_appliances"),
    key: "gas_appliances",
    columns: [
        {
            id: 1,
            text: localization("records.fields.type"),
            field: "appliancetype",
        },
        {
            id: 2,
            text: localization("records.fields.location"),
            field: "appliancelocation",
        },
        {
            id: 3,
            text: localization("records.fields.make"),
            field: "appliancemake",
        },
        {
            id: 4,
            text: localization("records.fields.model"),
            field: "appliancemodel",
        },
        {
            id: 5,
            text: localization("records.fields.flue_type"),
            field: "fluetype",
        },
        {
            id: 6,
            text: localization("records.fields.gc_number"),
            field: "gcn",
        },
        {
            id: 7,
            text: localization("records.fields.landlord"),
            field: "landlordequipment_text",
        },
        //{
        //    id: 8,
        //    text: localization("records.fields.equip"),
        //    field: "equip",
        //},
        {
            id: 8,
            text: localization("records.fields.hot_water_cylinder"),
            field: "is_hotwater_cylinder",
            width: "120px",
        },
    ],
};

const oil_appliances = {
    title: localization("records.table_titles.oil_appliances"),
    key: "oil_appliances",
    columns: [
        {
            id: 1,
            text: localization("records.fields.type"),
            field: "oilappliancetype_title",
        },
        {
            id: 2,
            text: localization("records.fields.location"),
            field: "oilappliancelocation",
        },
        {
            id: 3,
            text: localization("records.fields.make"),
            field: "oilappliancemake",
        },
        {
            id: 4,
            text: localization("records.fields.model"),
            field: "oilappliancemodel",
        },
        {
            id: 5,
            text: localization("records.fields.flue_type"),
            field: "oilappliancefluetype_title",
        },
        {
            id: 6,
            text: localization("records.fields.service_due"),
            field: "servicedue",
            date: "short",
        },
    ],
};

const archived = {
    title: localization("records.table_titles.archived"),
    key: "archived",
    columns: [
        {
            id: 1,
            text: localization("records.fields.details"),
            field: "details",
            date: "short",
        },
        {
            id: 2,
            text: localization("records.fields.issued_no"),
            field: "issued_no",
        },
        {
            id: 3,
            text: localization("records.fields.job_no"),
            field: "job_id",
            width: "90px",
        },
        {
            id: 4,
            text: localization("records.fields.modified_date"),
            field: "date",
        },
        {
            id: 5,
            text: localization("records.fields.modified_by"),
            field: "modifiedby",
            date: "short",
        },
        {
            id: 6,
            text: localization("records.fields.restore"),
            field: "restore",
            customLink: true,
            width: "90px",
        },
    ],
};

export const job_history = {
    title: localization("records.table_titles.job_history"),
    key: "job_history",
    columns: [
        {
            id: 1,
            text: localization("records.fields.created_date"),
            field: "created_date",
            date: "short",
        },
        {
            id: 2,
            text: localization("records.fields.modified_date"),
            field: "modified_date",
            date: "short",
        },
        {
            id: 3,
            text: localization("records.fields.modified_by"),
            field: "username",
        },
        {
            id: 4,
            text: localization("records.fields.details"),
            field: "details",
            width: "250%",
        },
        {
            id: 5,
            field: "icon",
            text: " ",
            width: "50px",
        },
    ],
};

export {
    jobs,
    property,
    invoices,
    outstanding_invoices,
    paid_invoices,
    history,
    gas_appliances,
    oil_appliances,
    archived,
};
