<template>
    <div class="toasts">
        <div class="toasts__wrapper">
            <transition-group name="appearance">
                <Toast
                    :notification="notification"
                    v-for="notification of visibleNotifications"
                    :key="notification.id"
                />
            </transition-group>
        </div>
    </div>
</template>

<script>
import { useNotificationsStore } from "@/shared/model";
import { defineComponent, computed } from "vue";
import Toast from "@/entities/Toast";

const NotificationsToaster = defineComponent({
    name: "NotificationsToaster",
    components: {
        Toast,
    },
    setup() {
        const notificationStore = useNotificationsStore();

        const visibleNotifications = computed(() => notificationStore.notifications);

        return {
            visibleNotifications,
        };
    },
});
export default NotificationsToaster;
</script>

<style lang="scss">
.custom {
    @import "./assets/toasts";
}
</style>
