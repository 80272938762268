<template>
    <div class="skeleton-info">
        <div class="pdf-preview">
            <Card disableShadow>
                <SkeletonCertificate />
            </Card>
        </div>
    </div>
</template>

<script>
import { defineComponent } from "vue";
import SkeletonCertificate from "./Certificate.vue";
import { Card } from "../../../Card";

const SkeletonPdfViewer = defineComponent({
    name: "SkeletonPdfViewer",
    components: {
        SkeletonCertificate,
        Card,
    },

    setup() {
        return {};
    },
});

export default SkeletonPdfViewer;
</script>

<style lang="scss" scoped>
.pdf-preview {
    padding: 20px;
    border: 1px solid #eaeaeb;
}
</style>
