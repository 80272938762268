import { input } from "@/shared/lib";
import { ref, computed, watch } from "vue";

export const useErrors = (template, data) => {
    const errors = ref({});
    const countErrors = computed(() => Object.values(errors.value).filter((e) => e).length);
    const orRequiredCount = computed(() => Object.values(errors.value).filter((e) => e == "orRequired").length);

    let checkErrors = () => {};

    if (template.form) {
        checkErrors = () => {
            errors.value = Object.assign(
                errors.value,
                input.dynamicFormValidation(template.form, data.value, errors.value)
            );
        };
    }

    watch(orRequiredCount, () => {
        checkErrors();
    });

    return { errors, countErrors, checkErrors };
};
