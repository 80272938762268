import * as password from "./password";

const inputs = {
    email:
        //eslint-disable-next-line
        /^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i,
    phone: /^\+?\d{10,16}$/,
    name: /(^[A-Za-z']{1,16})([ ]{0,1})([A-Za-z']{1,16})?([ ]{0,1})?([A-Za-z']{1,16})?([ ]{0,1})?([A-Za-z']{1,16})/,
    first_last_company_name: /^(?!.*[<>]).{1,100}$/, // Used for validation of first name, last name and company name.
};

/**
 * Validates the given data based on the specified key and returns an error message if the data is invalid.
 *
 * @param {any} data - The data to be validated.
 * @param {string} key - The key used to determine the validation rules.
 * @param {boolean} required - Indicates whether the data is required.
 * @param {boolean} ignore - Indicates whether the validation should be ignored.
 * @param {boolean} orRequired - Indicates whether the data is required if another field is present.
 * @param {object} allData - The entire data object.
 * @return {string} The error message if the data is invalid, or an empty string if the data is valid.
 */
export const validation = (data, key, required, ignore, orRequired, allData = {}, error = "") => {
    data = data?.value ?? data;
    const isEmpty = !data && required && !allData[orRequired];

    if (isEmpty) {
        if (orRequired) {
            error = "orRequired";
        } else {
            error = "empty";
        }
    } else if (!isEmpty && !!inputs[key] && !inputs[key]?.test(data) && data && !ignore) {
        error = "valid";
    } else if (!isEmpty && error && !ignore) {
        error = "";
    }

    return error;
};

/**
 * Validates all inputs in the given data object.
 *
 * @param {object} data - The data object containing inputs to be validated.
 * @return {object} An object containing validation errors for each input.
 */
export const allInputsValidation = (data) => {
    const errors = {};
    for (let key in data) {
        switch (true) {
            case key === "password":
                errors[key] = password.checkStandard(data[key]) === 4 ? "" : "valid";
                break;
            case key === "password_confirm":
                errors[key] = password.checkIdentity(data["password"], data[key]);
                break;
            case typeof data[key] === "boolean":
                errors[key] = data[key] ? "" : "empty";
                break;
            default:
                errors[key] = validation(data[key], key, true);
                break;
        }
    }
    return errors;
};

/**
 * Validates a dynamic form based on a template and data.
 *
 * @param {Array} template - The template for the form.
 * @param {Object} data - The data to be validated.
 * @param {Object} errors - The object to store validation errors.
 * @return {Object} The object containing validation errors for each component.
 */
export const dynamicFormValidation = (template = [], data, errors = {}) => {
    template.forEach((element) => {
        if (element.child) {
            dynamicFormValidation(element.child, data, errors);
        } else {
            const { component } = element;
            if (component?.name) {
                errors[component.name] = validation(
                    data?.[component.name],
                    component.validationType || component.name,
                    component.required,
                    !component.validation,
                    component.orRequired,
                    data,
                    errors[component.name]
                );
            }
        }
    });

    return errors;
};
