<template>
    <div class="custom-create-new__footer">
        <div class="custom-create-new__footer-buttons">
            <CustomButton
                v-bind="button"
                @click="actionEvent(button)"
                :disabled="disabled.includes(button.action)"
                :key="index"
                v-for="(button, index) of buttons"
            />
        </div>
    </div>
</template>

<script>
import { defineComponent } from "vue";
import { useI18n } from "vue-i18n";

import { CustomButton } from "@/shared/ui";

const ModalFooter = defineComponent({
    name: "ModalFooter",
    props: {
        buttons: {
            type: Array,
            default() {
                return [];
            },
        },
        disabled: {
            type: Array,
            default() {
                return [];
            },
        },
    },
    components: { CustomButton },
    setup(props, { emit }) {
        const i18n = useI18n();
        const actionEvent = (data) => {
            if (data.action && !props.disabled.includes(data.action)) {
                emit(data.action, data);
            }
        };
        return { i18n, actionEvent };
    },
});
export default ModalFooter;
</script>
<style lang="scss"></style>
