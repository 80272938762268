<template>
    <Row :gap="16">
        <Col class="certificate__content">
            <Card disableShadow type="certificate">
                <SkeletonCertificate />
            </Card>
        </Col>
        <Col class="certificate__actions"> <SkeletonCertificateActions /> </Col>
    </Row>
</template>

<script>
import { defineComponent } from "vue";
import SkeletonCertificateActions from "./CertificateActions.vue";
import SkeletonCertificate from "./Certificate.vue";
import { Row } from "../../../Row";
import { Col } from "../../../Col";
import { Card } from "../../../Card";

const SkeletonCertificatePage = defineComponent({
    name: "SkeletonCertificatePage",
    components: {
        SkeletonCertificateActions,
        SkeletonCertificate,
        Row,
        Col,
        Card,
    },

    setup() {
        return {};
    },
});

export default SkeletonCertificatePage;
</script>

<style lang="scss">
.certificate {
    &__content {
        width: calc(100% - 266px);

        @media screen and (max-width: 1500px) {
            width: calc(100% - 216px);
        }
    }

    &__actions {
        min-width: 200px;
        width: 100%;
        max-width: 250px;

        @media screen and (max-width: 1500px) {
            max-width: 200px;
        }
    }
}
</style>
