export const api_customer = {
    create: () => `/customer/edit`,
    save: (id) => `/customer/edit/${id}`,
    get: (id, query) => `/customer/info/${id ? id : ""}${query ? `?${query}` : ""}`,
    search: (data) => `/search/customer/?search=${data}`,
    create_search: () => `/ajax/addcust/`,
    tables: {
        jobs: (id, query) => `/ajax/jobs/${id}/cust?${query}`,
        property: (id, query) => `/ajax/properties/${id}/cust?${query}`,
        outstanding_invoices: (id, query) => `/ajax/invoices/${id}/cust?paid=no&${query}`,
        paid_invoices: (id, query) => `/ajax/invoices/${id}/cust?paid=paid&${query}`,
        history: (id, query) => `/ajax/gethistory/${id}/cust?${query}`,
    },
};

export const api_property = {
    copy_customer_address: (id) => `/ajax/getcustaddr/${id}`,
    create: () => `/property/edit`,
    save: (id) => `/property/edit/${id ? id : ""}`,
    get: (id, query) => `/property/info/${id ? id : ""}${query ? `?${query}` : ""}`,
    delete: (id, query) => `/property/delete/${id}?${query}`,
    property_reassign: () => `/property/reassign`,
    search: (data, customerId = "") => `/search/proplist/${customerId}?search=${data}`,
    create_search: (query) => `/ajax/addprop/${query ? query : ""}`,
    tables: {
        jobs: (id, query) => `/ajax/jobs/${id}/prop?${query}`,
        gas_appliances: (id, query) => `/ajax/appl/${id}/prop?${query}`,
        oil_appliances: (id, query) => `/ajax/oilappl/${id}/prop?${query}`,
        invoices: (id, query) => `/ajax/invoices/${id}/prop?${query}`,
        history: (id, query) => `/ajax/gethistory/${id}/prop?${query}`,
        archived: (id, query) => `/ajax/getarchive/${id}/prop?${query}`,
    },
};

export const api_job = {
    create: () => `/job/edit/`,
    save: (id) => `/job/edit/${id ? id : ""}`,
    get: (id, query) => `/job/info/${id ? id : ""}${query ? `?${query}` : ""}`,
    search: (data, jobId = "") => `/search/job/${jobId}?search=${data}`,
    tables: {
        job_history: (id, query) => `/job/view/${id}?${query}`,
    },
};

export const api_appliance = {
    save: {
        gas_appliance: () => `/ajax/addapp`,
        oil_appliance: () => `/ajax/addoilapp`,
    },
    get: {
        gas_appliance: (id) => `/ajax/getapp/${id}`,
        oil_appliance: (id) => `/ajax/getoilappl/${id}`,
    },
};
