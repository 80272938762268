<template>
    <div class="accordion accordion__wrapper" v-if="items.length">
        <div class="accordion__inner accordion__item" v-for="(item, index) of items" :key="index">
            <div
                class="accordion__title"
                :class="{ _active: activeItems.includes(index) }"
                @click="changeActive(index)"
            >
                <slot name="title" :item="item">
                    <p>{{ item.title }}</p>
                    <transition name="appearance">
                        <Icon icon="accordion_minus" v-if="activeItems.includes(index)" />
                        <Icon icon="accordion_plus" v-else />
                    </transition>
                </slot>
            </div>
            <transition name="appearance">
                <template v-if="activeItems.includes(index)">
                    <slot name="body" :item="item">
                        <p class="accordion__text mt-25" v-html="item.text" />
                    </slot>
                </template>
            </transition>
        </div>
    </div>
</template>

<script>
import { defineComponent, ref } from "vue";

import { Icon } from "../Icon";

const Accordion = defineComponent({
    name: "Accordion",
    components: { Icon },
    props: {
        items: {
            type: Array,
            default() {
                return [];
            },
        },
        disableMultiple: {
            type: Boolean,
        },
    },
    setup(props) {
        const activeItems = ref([]);

        const changeActive = (index) => {
            if (activeItems.value.includes(index)) {
                activeItems.value = activeItems.value.filter((el) => el !== index);
            } else {
                if (!props.disableMultiple) {
                    activeItems.value = [...activeItems.value, index];
                } else {
                    activeItems.value = [index];
                }
            }
        };

        return { activeItems, changeActive };
    },
});

export default Accordion;
</script>

<style lang="scss" scoped>
@import "./accordion";
</style>
