export const applianceIdKeys = {
    oil_appliance: "oilappliance_id",
    gas_appliance: "appliance_id",
};

export const applianceTypesFromTables = {
    oil_appliances: "oil_appliance",
    gas_appliances: "gas_appliance",
};

export const backendApplianceTypes = {
    oil_appliance: "oilappliance",
    gas_appliance: "appliance",
};
