import { recordLinks, recordIdKeys } from "../../config/constants";

/**
 * Generates a table link based on the given column, data, and type.
 *
 * @param {Object} column - The column object.
 * @param {Object} data - The data object.
 * @param {string} type - The type of the link.
 * @return {string|null} The generated table link or null if no link is found.
 */
export const generateTableLink = (column, data, type) => {
    if (!recordLinks[column.field] && !recordLinks[type]) {
        return null;
    }
    const idKey = recordIdKeys[column.field] || recordIdKeys[type];
    const link = recordLinks[column.field] || recordLinks[type];

    if (link && data[idKey]) {
        return link(data[idKey]);
    }
    return null;
};

/**
 * Generates a link based on the provided data and key.
 *
 * @param {Object} data - The data object.
 * @param {string} key - The key to determine the link.
 * @return {Object|string} - The generated link object or an empty string.
 */
export const generateLink = (data, key) => {
    if (recordLinks[key]) {
        const id = recordIdKeys[key];
        return { link: recordLinks[key]?.(data[id]), text: data[key] };
    }

    return data[key] || "";
};
