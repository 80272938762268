<template>
    <div class="custom-input-textarea">
        <TextHead as="h6" :text="title" v-if="!headless && title" class="mb-10" />
        <textarea
            v-model="currentData"
            :style="{ 'min-width': minWidth ? minWidth + 'px' : '' }"
            :class="{ _resizible: resizible }"
            :readonly="isDisabled"
            :placeholder="placeholder || ' '"
            :rows="rows"
            :name="name"
            :maxlength="maxLength"
            :autofocus="autofocus"
        />
    </div>
</template>

<script>
import { computed, defineComponent } from "vue";
import { TextHead } from "../TextHead";

const TextareaStandard = defineComponent({
    name: "TextareaStandard",
    props: {
        data: {
            type: String,
        },
        disabled: {
            type: Boolean,
            default: false,
        },
        headless: {
            type: Boolean,
            default: false,
        },
        title: {
            type: String,
            default: "",
        },
        rows: {
            type: Number,
            default: 4,
        },
        placeholder: {
            type: String,
            default: "",
        },
        minWidth: {
            type: Number,
        },
        maxLength: {
            type: Number,
            default: 252,
        },
        autofocus: {
            type: Boolean,
        },
        name: {
            type: String,
        },
        isArchived: {
            type: Boolean,
        },
        resizible: {
            type: Boolean,
        },
    },
    emits: ["update:data"],
    components: { TextHead },

    setup(props, { emit }) {
        const isDisabled = computed(() => props.disabled || props.isArchived);
        const currentData = computed({
            get() {
                return props.data;
            },
            set(data) {
                if (!isDisabled.value) {
                    emit("update:data", data);
                }
            },
        });

        return { currentData, isDisabled };
    },
});

export default TextareaStandard;
</script>

<style lang="scss" scoped>
@import "./textarea-standard";
</style>
