import { createI18n } from "vue-i18n";

/**
 * Loads locales data from the './locales' directory and returns an object containing datetimeFormats, numberFormats, and messages for each locale.
 *
 * @return {Object} An object containing datetimeFormats, numberFormats, and messages for each locale.
 */
const loadLocalesData = () => {
    const locales = require.context("./locales", true, /[A-Za-z0-9-_,\s]+\.json$/i);
    const localeData = {
        datetimeFormats: {},
        numberFormats: {},
        messages: {},
    };
    const keys = locales.keys();

    keys.forEach((key) => {
        const matched = key.match(/([A-Za-z0-9-_]+)\./i);

        if (matched && matched.length >= 1) {
            const locale = matched[1];
            localeData.numberFormats[locale] = locales(key).numberFormats;
            localeData.datetimeFormats[locale] = locales(key).datetimeFormats;
            localeData.messages[locale] = locales(key).messages;
        }
    });

    return localeData;
};

const data = loadLocalesData();

export const i18n = createI18n({
    locale: "en-GB",
    fallbackLocale: "en-GB",
    allowComposition: true,
    warnHtmlMessage: false,
    legacy: false,
    messages: data.messages,
    datetimeFormats: data.datetimeFormats,
    numberFormats: data.numberFormats,
});

export const availableLocales = [
    {
        name: "en-GB",
        locale: "en-GB",
        flag: "en-GB",
    },
];
