const { useApi, showError } = require("@/shared/api");
const { input, parseData, generateLink } = require("@/shared/lib");
import { api_customer, api_property, api_job, api_appliance } from "@/shared/api";

const api = { api_customer, api_property, api_job, api_appliance };

export const createNewRecord = async ({ tabInfo, key, isLoading }) => {
    const endpoint = api[`api_${key}`].create;
    tabInfo.error = input.dynamicFormValidation(tabInfo.template.form, tabInfo.data[key]);
    const hasErrors = Object.values(tabInfo.error).some((error) => !!error);
    if (endpoint && tabInfo.data[key]) {
        isLoading.value = true;
        try {
            if (hasErrors) {
                throw Error(JSON.stringify("required"));
            }
            const { data } = await useApi.post_form_data({
                link: endpoint(),
                data: parseData(tabInfo.data[key]),
            });
            const { link } = generateLink(data, key);
            window.location.href = link;
        } catch (error) {
            showError(error);
        } finally {
            isLoading.value = false;
        }
    }
};
