<template>
    <div class="skeleton" v-if="type">
        <component :is="`Skeleton${type}`" v-bind="data" />
    </div>
</template>

<script>
import { defineComponent } from "vue";
import * as components from "./components";

const SkeletonPage = defineComponent({
    name: "SkeletonPage",
    props: {
        type: {
            type: String,
        },
        data: {
            type: Object,
            default() {
                return {};
            },
        },
    },
    components,
    setup() {},
});

export default SkeletonPage;
</script>

<style lang="scss">
@import "./skeleton-page";
</style>
