<template>
    <h2
        class="text-head"
        :class="{
            [as]: as,
            [`_${color}`]: color,
            _centered: centered,
            _right: right,
            _bold: bold,
        }"
    >
        <slot>
            {{ text }}
        </slot>
    </h2>
</template>

<script>
import { defineComponent } from "vue";

const TextHead = defineComponent({
    name: "TextHead",
    props: {
        text: { type: String, default: "" },
        as: { type: String, default: "h2" },
        color: { type: String },
        centered: { type: Boolean },
        right: { type: Boolean },
        bold: { type: Boolean },
    },
});

export default TextHead;
</script>

<style lang="scss" scoped>
@import "./text-head";
</style>
