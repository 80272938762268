<template>
    <div>
        <Row :gap="20" class="mt-15">
            <Col :lg="4" :sm="4" v-for="index of items" :key="index">
                <Card disablePadding>
                    <div class="skeleton-card dashboard">
                        <div class="skeleton-info">
                            <CustomCardHeader :data="{ title: ' ', icon: ' ' }" />
                            <svg
                                width="312"
                                height="312"
                                viewBox="0 0 312 312"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                                class="ml-a mr-a mt-16 mb-16 w100"
                            >
                                <path
                                    d="M312 156C312 242.156 242.156 312 156 312C69.8436 312 0 242.156 0 156C0 69.8436 69.8436 0 156 0C242.156 0 312 69.8436 312 156ZM78 156C78 199.078 112.922 234 156 234C199.078 234 234 199.078 234 156C234 112.922 199.078 78 156 78C112.922 78 78 112.922 78 156Z"
                                    fill="#ddd"
                                />
                            </svg>
                            <SeparatePage class="mt-0 mb-10" />
                            <div class="row fdc">
                                <div class="col df jcsb">
                                    <h3 />
                                    <CustomButton text=" " wide :maxWidth="100" />
                                </div>
                                <div class="col df jcsb">
                                    <h3 />
                                    <h3 class="ml-20 mw30" />
                                </div>
                                <div class="col df jcsb">
                                    <h3 />
                                    <h3 class="ml-20 mw30" />
                                </div>
                            </div>
                        </div>
                    </div>
                </Card>
            </Col>
        </Row>
    </div>
</template>

<script>
import { defineComponent } from "vue";

import CustomCardHeader from "@/entities/CustomCardHeader";
import { SeparatePage } from "../../../SeparatePage";
import { CustomButton } from "../../../CustomButton";
import { Row } from "../../../Row";
import { Col } from "../../../Col";
import { Card } from "../../../Card";

const DashboardDonut = defineComponent({
    name: "DashboardDonut",
    props: {
        items: {
            type: Number,
            default: 3,
        },
    },
    components: {
        CustomCardHeader,
        SeparatePage,
        CustomButton,
        Row,
        Col,
        Card,
    },
});

export default DashboardDonut;
</script>
