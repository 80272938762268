<template>
    <div class="skeleton-info">
        <h1 class="mb-20" />
        <Card type="blue" v-if="!store.trial.isTrial">
            <div class="df jcsb aic">
                <div>
                    <h3 class="mb-20" />
                    <h1 class="mb-20" />
                    <div class="df mw30 mb-20">
                        <span />
                        <span class="ml-10" />
                    </div>
                    <p />
                </div>
                <CustomButton text=" " :maxWidth="200" big wide />
            </div>
        </Card>

        <SeparatePage class="mb-40 mt-40" />

        <Switcher :data="switcherData.items" v-model:active="switcherData.active" />

        <h3 class="mb-40 mt-40 ml-a mr-a mw30 w100" />

        <Row content="center" :gap="40">
            <Col :sm="4" v-for="index in 2" :key="index">
                <Card>
                    <div class="df aic jcsb mb-30">
                        <h3 class="mw30" />
                        <h3 class="mw30" />
                    </div>

                    <div class="df aic">
                        <div class="mw40">
                            <h3 class="mw100 mt-0 mb-0" />
                            <h3 class="mw100 mt-0 mb-0" />
                            <h3 class="mw100 mt-0 mb-0" />
                        </div>
                        <div class="mw30 ml-20">
                            <span class="mw100" />
                            <span class="mw100 mt-10" />
                        </div>
                    </div>

                    <div class="df aic mt-30">
                        <span />
                        <span class="ml-20" />
                    </div>
                    <SeparatePage class="mb-40 mt-40" />

                    <h3 />
                    <span />

                    <div class="benefits mt-40 mb-40">
                        <h3 v-for="idx of 6" :key="idx" />
                    </div>
                    <CustomButton text=" " wide big />
                </Card>
            </Col>
        </Row>
    </div>
</template>

<script>
import { defineComponent, reactive } from "vue";
import { useUserStore } from "@/shared/model";

import { Card } from "../../../Card";
import { CustomButton } from "../../../CustomButton";
import { SeparatePage } from "../../../SeparatePage";
import { Switcher } from "../../../Switcher";
import { Row } from "../../../Row";
import { Col } from "../../../Col";

const SkeletonCard = defineComponent({
    name: "SkeletonCard",
    components: { Card, CustomButton, SeparatePage, Switcher, Row, Col },
    setup() {
        const store = useUserStore();

        const switcherData = reactive({
            items: [
                {
                    id: 0,
                    text: " ",
                    value: "1",
                },
                {
                    id: 1,
                    text: " ",
                    value: "2",
                },
            ],
            active: "1",
        });

        return { switcherData, store };
    },
});

export default SkeletonCard;
</script>
