<template>
    <div class="additionals">
        <transition name="appearance">
            <div class="additionals__body mt-10 mb-20" v-if="meta.visible">
                <Card type="red" class="additionals__items" small disableShadow>
                    <a class="additionals__item additional" v-for="(additional, key) of additionals" :key="key">
                        <p class="additional__title">
                            {{ additional.title }}
                        </p>
                        <p class="additional__body">{{ additional.value }}</p>
                    </a>
                </Card>
            </div>
        </transition>
        <div class="additionals__switch">
            <p @click="changeVisible">
                {{ i18n.t("dashboard.card.additional", +meta.visible) }}
                <Icon icon="caret_down" :class="{ _active: meta.visible }" />
            </p>
        </div>
    </div>
</template>
<script>
import { defineComponent, ref } from "vue";
import { useI18n } from "vue-i18n";
import { Card } from "../Card";
import { Icon } from "../Icon";

const CustomCardItem = defineComponent({
    name: "CustomCardItem",
    props: {
        additionals: {
            type: Object,
            default() {
                return {};
            },
        },
    },
    components: { Card, Icon },
    setup() {
        const i18n = useI18n();

        const meta = ref({ visible: false, icon: "caret_down" });

        const changeVisible = () => {
            meta.value.visible = !meta.value.visible;
        };

        return { i18n, meta, changeVisible };
    },
});
export default CustomCardItem;
</script>

<style lang="scss">
@import "./additionals";
</style>
