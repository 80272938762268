import { reactive } from "vue";
import { io } from "socket.io-client";

export const useInitializeSocket = ({ socketUrl }) => {
    const socketRef = reactive({ data: null });

    const disconnectSocket = () => {
        if (socketRef?.data) {
            socketRef.data?.disconnect();
            socketRef.data = null;
        }
    };

    const initializeSocket = (data) => {
        const { token } = data || {};
        if (!token) {
            return;
        }

        if (socketRef?.data) {
            disconnectSocket();
        }

        if (token) {
            socketRef.data = io(socketUrl, {
                reconnectionDelayMax: 10000,
                transports: ["websocket"],
                auth: { token },
            });

            socketRef.data.on("connect_error", (error) => {
                console.error(error.message);
                throw new Error("Failed to connect to the socket server", { cause: error });
            });
        }
    };

    return {
        socket: socketRef,
        initializeSocket,
        disconnectSocket,
    };
};
