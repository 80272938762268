<template>
    <div class="attachment attachments__item" :class="{ active: data || attachment.user_attachment }" v-if="attachment">
        <Icon :icon="icon" />
        <div class="attachment__info">
            <div class="attachment__body">
                <a :href="attachment.href || attachment.url" v-if="attachment.href || attachment.url" target="_blank">
                    {{ attachment.name }}
                </a>
                <template v-else>
                    {{ attachment.name }}
                </template>
            </div>
            <div class="attachment__size" v-if="attachment.size">
                {{ attachment.size }}
            </div>
        </div>
        <InputCheckbox v-model:data="inputData" v-if="!+attachment.user_attachment" />
        <Icon icon="close" class="attachment__close" @click="deleteAttachment" v-else />
    </div>
</template>

<script>
import { computed, defineComponent } from "vue";

import { document_types } from "@/shared/config";
import { InputCheckbox } from "../InputCheckbox";
import { Icon } from "../Icon";

const Attachment = defineComponent({
    name: "Attachment",
    props: {
        attachment: {
            type: Object,
            required: true,
        },
        data: {
            type: Boolean,
            required: true,
        },
    },
    emits: ["update:data"],
    components: { Icon, InputCheckbox },
    setup(props, { emit }) {
        const inputData = computed({
            get() {
                return props.data;
            },
            set(data) {
                emit("update:data", data);
            },
        });

        const icon = computed(() => {
            const file_format = props.attachment.name?.split(".")?.pop();
            let icon;
            for (const key in document_types) {
                const value = document_types[key];
                if (value.includes(file_format.toLowerCase && file_format.toLowerCase())) {
                    icon = key;
                }
            }

            if (!icon) {
                icon = "undefined_doc";
            }
            return icon;
        });

        const deleteAttachment = () => {
            emit("deleteAttachment", props.attachment);
        };

        return { icon, inputData, deleteAttachment };
    },
});

export default Attachment;
</script>
<style lang="scss">
@import "./attachment";
</style>
