<template>
    <div class="multiple-tag__wrapper">
        <InputStandard
            v-model:data="currentData"
            :error="currentError"
            :title="title"
            :isArchived="isArchived"
            :small="small"
            disableValidation
        >
            <template #icon>
                <slot name="icon" />
            </template>
        </InputStandard>
    </div>
</template>

<script>
import { computed, defineComponent, ref, watch } from "vue";
import { InputStandard } from "../InputStandard";
import { debounce, input } from "@/shared/lib";

const MultipleTag = defineComponent({
    name: "MultipleTag",
    props: {
        data: {
            type: Array,
            required: true,
            default: () => [],
        },
        error: {
            type: String,
        },
        title: {
            type: String,
            default: "",
            required: false,
        },
        isArchived: {
            type: Boolean,
        },
        small: {
            type: Boolean,
        },
        stringEmail: {
            type: Boolean,
        },
    },
    inheritAttrs: false,
    components: { InputStandard },
    emits: ["update:error", "update:data"],
    setup(props, { emit }) {
        const actionItem = ref(false);
        const currentError = computed({
            get() {
                return props.error;
            },
            set(data) {
                emit("update:error", data);
            },
        });

        const splitEmails = (data) => {
            if (data?.length) {
                data = data.split(/[,; ]/g);
            }
            return data;
        };

        const currentData = computed({
            get() {
                let data = props.data;
                if (props.stringEmail) {
                    data = splitEmails(data);
                }
                return [...new Set(data)].join(";");
            },
            set(data) {
                if (props.stringEmail) {
                    emit("update:data", data);
                } else {
                    emit("update:data", splitEmails(data));
                }
            },
        });

        const validateEmails = debounce((data) => {
            let error = "";
            let newData = data;
            newData = splitEmails(newData);

            for (let value of newData) {
                error = input.validation(value, "email");
                if (error) {
                    break;
                }
            }
            currentError.value = error;
        }, 500);

        watch(currentData, (data) => {
            validateEmails(data);
        });

        return { actionItem, currentError, currentData };
    },
});
export default MultipleTag;
</script>

<style lang="scss"></style>
