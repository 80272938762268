//AUTH
import ResetPassword from "@/pages/ResetPassword";
import ForgotPassword from "@/pages/ForgotPassword";
import SignIn from "@/pages/SignIn";
import SignUp from "@/pages/SignUp";
import QuickbooksDisconnected from "@/pages/QuickbooksDisconnected";

//WRAPPERS OR TECHNICAL PAGES
import PageWrapper from "@/processors/PageWrapper";
import LegacyWrapper from "@/widgets/LegacyWrapper";
import Page404 from "@/pages/Page404";
import NotificationsToaster from "@/features/NotificationsToaster";

//PAGES
import CompanyPage from "@/pages/CompanyPage";
import IntegrationsPage from "@/pages/IntegrationsPage";
import { SubscriptionPage } from "@/pages/Subscription";
import Dashboard from "@/pages/Dashboard";
import PhotoManagment from "@/pages/PhotoManagment";
import CertificatePage from "@/pages/CertificatePage";
import PublicCertificate from "@/pages/PublicCertificate";
import CustomersPage from "@/pages/Customers";
import PropertyPage from "@/pages/Property";
import JobPage from "@/pages/Jobs";
import { EmailTemplate, EmailEditor } from "@/widgets/Email";
import CertificatePreview from "@/widgets/CertificatePreview";

export default {
    PageWrapper,
    //AuthWrapper,
    LegacyWrapper,

    NotificationsToaster,
    CompanyPage,
    IntegrationsPage,
    SubscriptionPage,
    Dashboard,
    Page404,
    QuickbooksDisconnected,
    CertificatePage,
    PublicCertificate,
    PhotoManagment,
    CustomersPage,
    PropertyPage,
    JobPage,
    EmailTemplate,
    EmailEditor,
    CertificatePreview,
    //AUTH
    ForgotPassword,
    SignIn,
    SignUp,
    ResetPassword,
};
