<template>
    <div
        class="custom-input"
        :class="{
            clearable: clearable && data,
            '_hidden-name': !title || outsideTitle,
            _sticky: sticky,
            [`_position-${position}`]: position,
            _icon: icon || $slots.icon,
            '_disable-border': disableBorder,
            '_hide-actions': hideActions,
            [`_${size}`]: size,
            _disabled: isDisabled,
            _wide: wide,
            _small: small,
        }"
        :style="{ minWidth: minWidth ? minWidth + 'px' : minWidth }"
        v-if="type"
    >
        <TextHead as="h6" :bold="bold" :text="title" v-if="outsideTitle && title" class="mb-10" />
        <div
            class="custom-input__wrapper"
            :class="{
                _error: error,
                _success: success,
            }"
        >
            <input
                class="custom-input__field"
                v-bind="autocomplete ? {} : { 'data-1p-ignore': '' }"
                :type="type"
                :placeholder="placeholder"
                step="0.01"
                :readonly="isDisabled"
                v-model="currentData"
                :wide="wide"
                :name="title"
                :max="max"
                :min="min"
                :autocomplete="autocomplete ? 'on' : 'off'"
                @focus.prevent="$emit('onFocus', $event)"
                @keyup.enter.prevent="action('onEnter', $event)"
                @keyup.space="action('onSpace', $event)"
                @blur.prevent="action('onBlur', $event)"
                @keydown.,="action('onComma', $event)"
                @keydown.;="action('onSemiColon', $event)"
                @submit.prevent="onEnter"
            />
            <span
                class="custom-input__label"
                :class="{
                    _active: data,
                }"
                v-if="!outsideTitle"
            >
                {{ title }}<span class="required" v-if="required && title">*</span>
            </span>
            <div class="icons__wrapper">
                <slot name="icon">
                    <span v-if="textinfo" class="custom-input__text-info">
                        {{ textinfo }}
                    </span>
                    <icon
                        :icon="icon"
                        :class="{
                            _active: data,
                        }"
                    />
                </slot>
                <icon icon="close" class="icon_close" v-if="clearable && data" @click="clearData" />
            </div>
        </div>

        <transition name="appearance">
            <p class="custom-input__error" v-if="error">{{ computedError }}</p>
        </transition>

        <transition name="appearance">
            <p class="custom-input__success" v-if="success">
                {{ i18n.t(`auth.form.forgot_success`) }}
            </p>
        </transition>
        <p class="custom-input__description" v-if="description">
            {{ i18n.t(`auth.form.${name}_description`) }}
        </p>
    </div>
</template>

<script>
import { computed, defineComponent, ref } from "vue";
import { useI18n } from "vue-i18n";
import { input } from "@/shared/lib";

import { Icon } from "../Icon";
import { TextHead } from "../TextHead";

const InputStandard = defineComponent({
    name: "InputStandard",
    props: {
        type: {
            type: String,
            default: "text",
        },
        icon: {
            type: String,
            default: "",
        },
        title: {
            type: String,
            default: "",
        },
        name: {
            type: String,
        },
        required: {
            type: Boolean,
            default: false,
        },
        clearable: {
            type: Boolean,
            default: false,
        },
        data: {
            type: [String, Number, Array],
            required: true,
            default: "",
        },
        error: {
            type: String,
            default: "",
        },
        success: {
            type: Boolean,
            default: false,
        },
        placeholder: {
            type: String,
            default: " ",
        },
        description: {
            type: String,
            default: "",
        },
        sticky: {
            type: Boolean,
            default: false,
        },
        disabled: {
            type: Boolean,
            default: false,
        },
        outsideTitle: {
            type: Boolean,
            default: false,
        },
        position: {
            type: String,
            default: "",
        },
        minWidth: {
            type: Number,
        },
        disableBorder: {
            type: Boolean,
        },
        hideActions: {
            type: Boolean,
        },
        disableValidation: {
            type: Boolean,
        },
        validation: {
            type: Boolean,
        },
        validationType: {
            type: String,
        },
        textinfo: {
            type: String,
        },
        maxChars: {
            type: Number,
        },
        size: {
            type: String,
            default: "standard",
        },
        max: {
            type: Number,
        },
        min: {
            type: Number,
        },
        bold: {
            type: Boolean,
        },
        wide: {
            type: Boolean,
        },
        orRequired: {
            type: String,
        },
        allData: {
            type: Object,
        },
        orRequiredName: {
            type: String,
        },
        isArchived: {
            type: Boolean,
        },
        small: {
            type: Boolean,
        },
        autocomplete: {
            type: Boolean,
        },
    },
    components: {
        Icon,
        TextHead,
    },
    emits: ["onFocus", "onBlur", "onSpace", "onEnter", "updateData", "update:error", "update:success", "update:data"],
    setup(props, { emit }) {
        const i18n = useI18n();

        const errorsChecked = ref(false);
        const currentData = computed({
            get() {
                return props.data;
            },
            set(data) {
                changeElement(data);
            },
        });

        const computedError = computed(() => {
            if (props.title && ["valid", "empty", "orRequired"].includes(props.error)) {
                return i18n.t(`errors.${props.error}`, { name: props.title, secondname: props.orRequiredName });
            } else if (!props.error?.includes(" ") && props.name) {
                return i18n.t(`auth.form.${props.name}_${props.error}`);
            }

            return props.error;
        });

        const isDisabled = computed(() => props.disabled || props.isArchived);

        const changeElement = (data) => {
            const { max, min } = props;

            let isInRange;
            switch (true) {
                case !!max && !!min:
                    isInRange = +max >= +data && +min <= +data;
                    break;
                case !!max:
                    isInRange = +max >= +data;
                    break;
                case !!min:
                    isInRange = +min <= +data;
                    break;
                default:
                    isInRange = true;
            }
            if (isInRange) {
                emit("update:data", data);
                emit("updateData", data);
                setTimeout(() => {
                    validate();
                });
            }
        };

        const validate = (isBlur) => {
            if (props.disableValidation) {
                return;
            }

            if (props.error) {
                errorsChecked.value = true;
            }

            const { required, validation, orRequired, allData } = props;

            if ((required || validation) && (errorsChecked.value || isBlur)) {
                errorsChecked.value = true;
                const error = input.validation(
                    currentData.value,
                    props.validationType || props.type || props.name,
                    required,
                    false,
                    orRequired,
                    allData
                );
                emit("update:error", error);

                if (props.maxChars && currentData.value.length > props.maxChars) {
                    emit("update:error", i18n.t("errors.max_chars", { num: props.maxChars }));
                }
            } else {
                emit("update:error", "");
                if (props.success) {
                    emit("update:success", false);
                }
            }
        };

        const action = (name, event) => {
            emit(name, event);
            validate(true);
        };

        const clearData = () => {
            currentData.value = "";
        };

        return { i18n, currentData, computedError, isDisabled, changeElement, action, clearData };
    },
});
export default InputStandard;
</script>

<style lang="scss">
.custom {
    @import "./input-standard";
}
</style>
