<template>
    <component
        :is="isDisabled ? 'button' : buttonComponent"
        v-if="button.text || button.icon"
        type="button"
        :disabled="isDisabled"
        :download="download"
        :href="href"
        :target="target"
        :class="{
            button: true,
            [`_${button.type}`]: button.type,
            _transparent: transparent,
            _small: small,
            _ultrasmall: ultrasmall,
            '_like-input': likeinput,
            _standard: standard,
            _big: big,
            _translucent: translucent,
            _wide: wide,
        }"
        :style="{
            maxWidth: `${maxWidth}px`,
        }"
    >
        <icon :icon="button.icon" v-if="button.position !== 'right' && button.icon" />
        <p v-if="button.text">
            {{ button.text }}
        </p>
        <icon :icon="button.icon" v-if="button.position === 'right' && button.icon" />
    </component>
</template>

<script>
import { computed, defineComponent } from "vue";
import { useI18n } from "vue-i18n";

import { Icon } from "../Icon";

const CustomButton = defineComponent({
    name: "CustomButton",
    components: { Icon },
    props: {
        type: {
            type: String,
            default: "default",
        },
        text: {
            type: String,
            default: "",
        },
        position: {
            type: String,
            default: "left",
        },
        icon: {
            type: String,
            default: "",
        },
        transparent: {
            type: Boolean,
        },
        disabled: {
            type: Boolean,
        },
        big: {
            type: Boolean,
        },
        standard: {
            type: Boolean,
            default: true,
        },
        small: {
            type: Boolean,
        },
        ultrasmall: {
            type: Boolean,
        },
        translucent: {
            type: Boolean,
        },
        maxWidth: {
            type: Number,
        },
        wide: {
            type: Boolean,
        },
        buttonComponent: {
            type: String,
            default: "button",
        },
        href: {
            type: String,
        },
        required: {
            type: Boolean,
        },
        errors: {
            type: Object,
            default() {
                return {};
            },
        },
        target: {
            type: String,
        },
        likeinput: {
            type: Boolean,
        },
        page: {
            type: String,
        },
        download: {
            type: Boolean,
            default: null,
        },
    },

    setup(props) {
        const i18n = useI18n();

        const button = computed(() => {
            let text;
            if (props.text) {
                return props;
            }

            if (props.page) {
                text = i18n.t(`buttons.${props.page}.${props.type}`);
            } else if (props.type !== "default") {
                text = i18n.t(`buttons.${props.type}`);
            }

            return {
                text,
                ...props,
            };
        });

        const isDisabled = computed(() => {
            const hasErrors = Object.values(props.errors).some((error) => !!error);
            return props.disabled || (props.required && hasErrors);
        });

        return { button, i18n, isDisabled };
    },
});
export default CustomButton;
</script>
<style lang="scss">
.custom {
    @import "./button";
}
</style>
