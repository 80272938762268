<template>
    <span
        class="icon"
        :class="{ _disabled: disabled, _changable: changable }"
        v-html="customIcons[icon]"
        v-if="customIcons[icon]"
    />
    <span
        class="icon"
        :class="{ _disabled: disabled, _changable: changable }"
        v-html="icons[icon]"
        v-else-if="icons[icon]"
    />
</template>

<script>
import { defineComponent } from "vue";

import * as icons from "../../icons";

const Icon = defineComponent({
    name: "Icon",
    props: {
        icon: {
            type: String,
        },
        disabled: {
            type: Boolean,
        },
        changable: {
            type: Boolean,
        },
        customIcons: {
            type: Object,
            default() {
                return {};
            },
        },
    },

    setup() {
        return { icons };
    },
});
export default Icon;
</script>

<style lang="scss" scoped>
@import "./icon";
</style>
