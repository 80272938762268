<template>
    <div
        class="row"
        :class="{
            _wrap: wrap,
        }"
        :style="{
            '--gap': `${gap}px`,
            justifyContent: content,
            alignItems: align,
        }"
    >
        <slot />
    </div>
</template>
<script>
import { defineComponent } from "vue";

const Row = defineComponent({
    name: "Row",
    props: {
        gap: {
            type: Number,
            default: 16,
        },
        wrap: {
            type: Boolean,
            default: false,
        },
        content: {
            type: String,
        },
        align: {
            type: String,
        },
    },
});
export default Row;
</script>

<style lang="scss" scoped>
@import "./row";
</style>
