<template>
    <div class="footer">
        <p>{{ version }}</p>
    </div>
</template>
<script>
import { defineComponent, onMounted, ref } from "vue";

const PageFooter = defineComponent({
    name: "PageFooter",
    setup() {
        const version = ref("");

        onMounted(() => {
            version.value = window.WebConfig?.version;
        });

        return { version };
    },
});
export default PageFooter;
</script>
<style lang="scss">
@import "./assets/page-footer";
</style>
