<template>
    <Card disableShadow type="certificate">
        <div class="skeleton-info">
            <div class="df jcsb mt-16" v-for="i of 3" :key="i">
                <p class="mw10 mt-10" />
                <CustomButton class="w100 ml-16" transparent text=" " />
            </div>
            <div class="mt-16">
                <CustomButton class="w100 mb-16 h50" transparent text=" " />
                <CustomButton class="w100 mb-16 h500" transparent text=" " />
            </div>
            <div class="mt-16">
                <h3 class="mw20 mb-16" />
                <div class="df">
                    <p class="mw10" />
                    <p class="mw10 ml-16" />
                </div>
                <p class="mw20 mt-50 ml-a"></p>
                <DragAndDrop class="mt-16" />
            </div>
        </div>
    </Card>
</template>

<script>
import { defineComponent } from "vue";

import { Card } from "../../../Card";
import { CustomButton } from "../../../CustomButton";
import DragAndDrop from "./DragAndDrop.vue";

const SkeletonEmailEditor = defineComponent({
    name: "SkeletonEmailEditor",
    components: {
        Card,
        CustomButton,
        DragAndDrop,
    },
});

export default SkeletonEmailEditor;
</script>
