<template>
    <div
        class="status"
        :class="{
            [`_${type}`]: type,
            _big: big,
            _small: small,
            '_hidden-border': disableBorder,
            _centered: centered,
            _wide: wide,
        }"
        :data-tag="tag"
    >
        <Icon :icon="icon" v-if="position === 'left'" />
        <slot>
            {{ text }}
        </slot>
        <Icon :icon="icon" v-if="position === 'right'" />
        <div class="status__close" v-if="closable" @click="$emit('close', { text, index })"><Icon icon="close" /></div>
    </div>
</template>

<script>
import { defineComponent } from "vue";
import { Icon } from "../Icon";

const Status = defineComponent({
    name: "Status",
    components: {
        Icon,
    },
    props: {
        type: {
            type: String,
        },
        index: {
            type: [Number, String],
        },
        text: {
            type: String,
        },
        disableBorder: {
            type: Boolean,
        },
        big: {
            type: Boolean,
        },
        small: {
            type: Boolean,
        },
        closable: {
            type: Boolean,
        },
        centered: {
            type: Boolean,
        },
        icon: {
            type: String,
        },
        position: {
            type: String,
            default: "left",
        },
        tag: {
            type: String,
        },
        wide: {
            type: Boolean,
        },
    },
    emits: ["close"],
});
export default Status;
</script>

<style lang="scss">
@import "./status";
</style>
