<template>
    <div class="records skeleton-info">
        <Row :gap="30">
            <Col :sm="9">
                <Card>
                    <div>
                        <h1 class="mw40 w100 mb-16" />
                        <div class="df g16 aic wrap">
                            <CustomButton transparent text=" " wide :maxWidth="300" />
                            <CustomButton transparent text=" " wide :maxWidth="300" />
                            <CustomButton transparent text=" " wide :maxWidth="300" />
                            <CustomButton transparent text=" " wide :maxWidth="300" />
                            <CustomButton transparent text=" " wide :maxWidth="300" />
                        </div>
                    </div>
                    <div class="mt-30">
                        <h1 class="mw40 w100 mb-16" />
                        <CustomButton transparent class="mb-16" text=" " wide />
                        <div class="df g20 aic wrap">
                            <CustomButton transparent text=" " wide :maxWidth="300" />
                            <CustomButton transparent text=" " wide :maxWidth="300" />
                            <CustomButton transparent text=" " wide :maxWidth="300" />
                        </div>
                    </div>
                    <div class="mt-30">
                        <h1 class="mw40 w100 mb-16" />
                        <CustomButton transparent class="mb-16" text=" " wide />
                        <div class="df g20 aic wrap">
                            <CustomButton transparent text=" " wide :maxWidth="300" />
                            <CustomButton transparent text=" " wide :maxWidth="300" />
                            <CustomButton transparent text=" " wide :maxWidth="300" />
                        </div>
                    </div>
                    <div class="mt-30">
                        <h1 class="mw40 w100 mb-16" />
                        <CustomButton transparent class="mb-16 h200" text=" " wide />
                    </div>
                </Card>
            </Col>
            <Col :sm="3">
                <Card small>
                    <CustomButton transparent text=" " wide />
                    <CustomButton transparent text=" " class="mt-16" wide />
                    <CustomButton transparent text=" " class="mt-16" wide v-if="isEditPage" />
                </Card>
                <CustomButton transparent text=" " class="mt-16" wide v-if="isEditPage" />
                <CustomButton transparent text=" " class="mt-16" wide v-if="isEditPage" />
            </Col>
        </Row>
        <Row v-if="isEditPage">
            <Col :sm="9" class="mt-30">
                <Card small>
                    <SkeletonTable class="" />
                </Card>
            </Col>
        </Row>
    </div>
</template>

<script>
import { computed, defineComponent } from "vue";

import { Row } from "../../../Row";
import { Col } from "../../../Col";
import { CustomButton } from "../../../CustomButton";
import { Card } from "../../../Card";
import SkeletonTable from "./Table.vue";

const SkeletonCertificate = defineComponent({
    name: "SkeletonCertificate",
    components: { Row, Col, Card, CustomButton, SkeletonTable },
    props: {
        pageType: {
            type: String,
        },
    },
    setup(props) {
        const isEditPage = computed(() => props.pageType === "edit");
        return { isEditPage };
    },
});

export default SkeletonCertificate;
</script>
