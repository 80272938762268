import { createPinia } from "pinia";
import { i18n } from "@/shared/lib";
import "@/shared/ui/assets/scss/_main.scss";

import * as components from "./components";

const componentsList = components?.default;
const pinia = createPinia();

const customComponents = {
    install: (Vue) => {
        for (const name in componentsList) {
            Vue.component(name, componentsList[name]);
        }
    },
};

if (typeof window !== "undefined" && window.app?.version) {
    window.app?.use(pinia);
    window.app?.use(customComponents);
    window.app?.use(i18n);
}
