<template>
    <div v-if="!loading">
        <div v-if="!file || !removable">
            <div :class="{ 'drop-zone': true, _small: small }" @dragenter="drag(true)" @dragleave="drag(false)">
                <slot>
                    <div class="drop-zone__wrapper" :class="{ 'drop-zone__over': dragging }">
                        <div class="drop-zone__info">
                            <Icon icon="drag_and_drop" />
                            <p class="drop-zone__title" v-html="textInfo.title" />
                            <div class="drop-zone__upload-limit-info" v-if="!small">
                                <div v-if="allowExtensions">{{ textInfo.extensions }}</div>
                            </div>
                        </div>
                    </div>
                </slot>
                <input type="file" multiple @change="onChange" />
            </div>
        </div>
        <CustomButton
            @click="removeFile"
            small
            transparent
            v-else-if="removable"
            :text="i18n.t(`certificate.email_editor.remove_file`)"
        />
    </div>
    <SkeletonPage type="DragAndDrop" :data="{ small }" v-else />
</template>

<script>
import { computed, defineComponent, ref } from "vue";
import { useI18n } from "vue-i18n";
import { useNotificationsStore } from "@/shared/model";

import { CustomButton } from "../CustomButton";
import { SkeletonPage } from "../SkeletonPage";
import { Icon } from "../Icon";

const DragAndDrop = defineComponent({
    name: "DragAndDrop",
    props: {
        extensions: {
            type: [Array, Object],
            default() {
                return ["tif", "tiff", "bmp", "jpg", "jpeg", "gif", "png", "eps", "svg"];
            },
        },
        removable: {
            type: Boolean,
        },
        loading: {
            type: Boolean,
        },
        small: {
            type: Boolean,
        },
    },
    components: {
        CustomButton,
        Icon,
        SkeletonPage,
    },
    emits: ["uploadFile"],

    setup(props, { emit }) {
        const i18n = useI18n();
        const notificationStore = useNotificationsStore();

        const file = ref("");
        const dragging = ref(false);

        const allowExtensions = computed(() => {
            let allow = [];
            if (Array.isArray(props.extensions)) {
                allow = [...props.extensions];
            } else {
                Object.values(props.extensions).forEach((el) => (allow = [...allow, ...el]));
            }
            return allow;
        });

        const textInfo = computed(() => {
            const title = !props.small
                ? i18n.t(`certificate.email_editor.${dragging.value ? "drop" : "drop_click"}`)
                : i18n.t(`certificate.email_editor.${dragging.value ? "drop" : "drop_small"}`);
            const commaExtensions = [...allowExtensions.value];
            const lastExtenstion = commaExtensions.pop();
            const extensions = i18n.t(`certificate.email_editor.extensions`, {
                extensions: `${commaExtensions.join(", ")} and ${lastExtenstion}`,
            });

            return { title, extensions };
        });

        const onChange = (e) => {
            const files = e.target.files || e.dataTransfer.files;

            if (!files.length) {
                dragging.value = false;
                return;
            }
            for (const file of files) {
                createFile(file);
            }

            e.target.value = null;
        };

        const createFile = (data) => {
            const isDataAllow =
                !allowExtensions.value.length ||
                allowExtensions.value.some((el) => data.type?.toLowerCase()?.includes(el));
            if (!isDataAllow) {
                notificationStore.addNotification({
                    body: `Please select ${allowExtensions.value.join(" , ")} files`,
                    type: "error",
                });
                dragging.value = false;
                return;
            }

            // 8388608 is 8mib in bytes (8 * 1024 * 1024)
            if (data.size > 8388608) {
                notificationStore.addNotification({
                    body: i18n.t("certificate.email_editor.file_is_over"),
                    type: "error",
                });
                dragging.value = false;
                return;
            }

            dragging.value = false;
            emit("uploadFile", data);
        };

        const drag = (data) => {
            dragging.value = data;
        };

        const removeFile = () => {
            file.value = "";
        };

        return { file, dragging, i18n, allowExtensions, textInfo, onChange, createFile, removeFile, drag };
    },
});

export default DragAndDrop;
</script>

<style lang="scss">
@import "./drag-and-drop";
</style>
