<template>
    <div class="certificate skeleton-info">
        <Row>
            <Col :sm="6">
                <div>
                    <div class="image">
                        <img class="image__img" />
                    </div>
                    <h3 />
                    <p class="mb-8" />
                    <p class="mb-8" />
                    <p class="mb-8" />
                    <p class="mb-8" />
                    <p class="mb-8" />
                    <CustomButton transparent class="mt-16" text=" " wide :maxWidth="300" />
                </div>
            </Col>
            <Col :sm="6">
                <h1 class="ml-a" />
                <h3 class="ml-a" />
                <p class="mb-8 ml-a" />
                <p class="mb-8 ml-a" />
                <p class="mb-8 ml-a" />
                <p class="mb-8 ml-a" />
                <p class="mb-8 ml-a" />

                <div class="mt-40">
                    <h3 class="ml-a" />
                    <CustomButton transparent text=" " class="ml-a" wide :maxWidth="300" />
                </div>
                <div class="mt-40">
                    <h3 class="ml-a" />
                    <p class="mb-8 ml-a" />
                    <p class="mb-8 ml-a" />
                    <p class="mb-8 ml-a" />
                    <p class="mb-8 ml-a" />
                    <p class="mb-8 ml-a" />
                </div>
            </Col>
        </Row>
        <SkeletonTable class="mt-16 mb-16" />
        <Row :gap="40">
            <Col :sm="4">
                <h3 />
                <CustomButton transparent text=" " wide :maxWidth="300" />
                <h3 />
                <CustomButton transparent text=" " wide :maxWidth="300" />
            </Col>
            <Col :sm="4">
                <h3 />
                <p class="mb-8" />
                <p class="mb-8" />
                <p class="mb-8" />
                <p class="mb-8" />
                <p class="mb-8" />
            </Col>
            <Col :sm="4">
                <h3 class="ml-a" />
                <h3 class="ml-a" />
                <h3 class="ml-a" />
                <h3 class="ml-a" />
            </Col>
        </Row>
    </div>
</template>

<script>
import { defineComponent } from "vue";

import { Row } from "../../../Row";
import { Col } from "../../../Col";
import { CustomButton } from "../../../CustomButton";
import SkeletonTable from "./Table.vue";

const SkeletonCertificate = defineComponent({
    name: "SkeletonCertificate",
    components: { Row, Col, CustomButton, SkeletonTable },

    setup() {
        return {};
    },
});

export default SkeletonCertificate;
</script>
