<template>
    <Card class="photo-managment">
        <div class="photo-managment__title mb-15">
            <TextHead as="h3" :text="i18n.t('job.photos.title')" />
        </div>
        <DragAndDrop class="mt-16" :extensions="photo_extensions" :loading="isLoading" @uploadFile="uploadFile" />
        <SeparatePage class="mt-35 mb-35" />
        <GridGallery v-model:images="images" @deleteItem="deleteItem" :loading="isLoading" />

        <SeparatePage class="mt-35 mb-35" />
        <div class="photo-managment__title">
            <CustomButton :text="i18n.t('buttons.cancel')" class="ml-a" translucent @click="cancel" />
            <div class="custom-divider ml-5 mr-5" />
            <CustomButton :text="i18n.t('job.photos.save')" @click="savePhotos" :disabled="!images.length" />
        </div>
    </Card>
</template>
<script>
import { computed, defineComponent, onMounted, ref } from "vue";
import { photo_extensions } from "@/shared/config";

import { useI18n } from "vue-i18n";
import { getBase64, getItemId } from "@/shared/lib";
import { useApi } from "@/shared/api";
import { api_delete_photo, api_photos_save } from "./api";

import { TextHead, Card, DragAndDrop, SeparatePage, CustomButton } from "@/shared/ui";
import GridGallery from "@/widgets/GridGallery";

const PhotoManagment = defineComponent({
    name: "PhotoManagment",
    components: { TextHead, Card, DragAndDrop, SeparatePage, GridGallery, CustomButton },
    setup() {
        const i18n = useI18n();

        const images = ref([]);
        const isLoading = ref(false);
        const currentId = computed(() => +getItemId() || " ");

        const deleteItem = async (data) => {
            isLoading.value = true;

            if (data.photo_id) {
                const res = await useApi.get({ link: api_delete_photo(data.photo_id) });

                if (res) {
                    images.value = images.value.filter((el, index) => index !== data.index);
                }
            } else {
                images.value = images.value.filter((el, index) => index !== data.index);
            }

            isLoading.value = false;
        };

        const uploadFile = async (data) => {
            const url = await getBase64(data);
            let title = data.name.split(".");
            title.pop();

            const index = images.value.length;

            images.value.push({
                index,
                title: title.join(""),
                url,
                filename: data.name,
                private: "0",
                archive: "0",
                file: data,
            });
        };

        const savePhotos = async () => {
            isLoading.value = true;
            const data = new FormData();

            images.value.forEach((el, index) => {
                if (el.photo_id) {
                    data.append(`photo[${el.photo_id}][title]`, el.title);
                    data.append(`photo[${el.photo_id}][private]`, +el.private);
                } else {
                    data.append(`new_files[${index}]`, el.file);
                    data.append(`new[${index}][title]`, el.title);
                    data.append(`new[${index}][filename]`, el.filename);
                }
            });

            await useApi.post_form_data({ link: api_photos_save(currentId.value), data });
            isLoading.value = false;
        };

        const getPhotos = async () => {
            isLoading.value = true;
            images.value = await useApi.get({ link: window.location.href });
            isLoading.value = false;
        };

        const cancel = () => {
            window.location.href = `/job/view/${currentId.value}`;
        };

        onMounted(async () => {
            await getPhotos();
        });

        return { i18n, images, photo_extensions, isLoading, currentId, cancel, deleteItem, uploadFile, savePhotos };
    },
});
export default PhotoManagment;
</script>
<style lang="scss">
@import "./photo-managment";
</style>
