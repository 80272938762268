const widget = "v1/widgets";

export const api_initialize = (link) => `${link}/${widget}/session/initialize/`;

export const api_feed = (link, page = 0) => `${link}/${widget}/notifications/feed?page=${page}`;

export const api_settings = (link, id = "") => `${link}/${widget}/preferences/${id}`;

export const api_unseen = (link) => `${link}/${widget}/notifications/unseen/`;

export const api_mark_as = (link) => `${link}/${widget}/messages/markAs`;

export const api_messages = (link, id) => `${link}/${widget}/messages/${id}`;

export const api_read = (link) => `${link}/${widget}/messages/read`;
