<template>
    <div class="skeleton-info">
        <div class="df aic jcfe">
            <h3 class="mw40 mr-5" />
            :
            <h3 class="mw40 ml-5" />
        </div>
    </div>
</template>

<script>
import { defineComponent } from "vue";

const SkeletonCertificatePayments = defineComponent({
    name: "SkeletonCertificatePayments",
});

export default SkeletonCertificatePayments;
</script>
