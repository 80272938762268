import { defineStore } from "pinia";
import { deepCopy } from "@/shared/lib";

import { computed, ref } from "vue";
import { decodeHtmlEntity } from "@/shared/lib";

export const useNotificationsStore = defineStore("notificationsStore", () => {
    const messages = window.WebConfig?.messages;
    const allNotifications = ref([]);

    const listener = document.addEventListener("setNotification", (el) => {
        addNotification(el.detail);
    });

    const notifications = computed(() => allNotifications.value.filter((el) => el.visible));

    /**
     * Adds a notification to the list of notifications.
     *
     * @param {Object} data - The data object containing the notification details.
     * @param {string} data.body - The body of the notification.
     * @param {string} [data.head] - The head of the notification.
     * @param {boolean} [data.timeless] - Indicates if the notification should not be automatically hidden.
     * @param {number} [data.time] - The time in milliseconds after which the notification should be hidden. Default is 3000ms.
     */
    const addNotification = (data) => {
        if (data) {
            const notification = deepCopy(data);
            const id = allNotifications.value.length + 1;
            notification.id = id;
            notification.visible = true;
            notification.body = decodeHtmlEntity(notification.body).trim();
            notification.head = decodeHtmlEntity(notification?.head).trim();
            if (notification.body || notification.head) {
                allNotifications.value.unshift(notification);
            }

            if (!notification.timeless)
                setTimeout(() => {
                    const idx = allNotifications.value.findIndex((item) => notification.id === item.id);
                    allNotifications.value[idx].visible = false;
                }, data.time ?? 3000);
        }
    };

    if (messages?.length) {
        messages.forEach((item) => {
            item.timeless = true;
            addNotification(item);
        });
    }

    /**
     * Deletes a notification with the specified ID from the list of all notifications.
     *
     * @param {number} id - The ID of the notification to delete.
     * @return {void} This function does not return a value.
     */
    const deleteNotification = (id) => {
        const idx = allNotifications.value.findIndex((item) => item.id === id);
        allNotifications.value[idx].visible = false;
    };

    window.onbeforeunload = () => {
        document.removeEventListener("setNotification", listener);
    };

    return { allNotifications, notifications, addNotification, deleteNotification };
});
