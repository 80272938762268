<template>
    <div class="switcher" v-if="data.length">
        <div class="switcher__wrapper">
            <div
                class="switcher__inner switcher__item"
                :class="{
                    _active: item.value === active || item.id === active,
                }"
                v-for="item of data"
                :key="item.id"
                @click="changeActive(item.value ?? item.id)"
            >
                {{ item.text }}
            </div>
        </div>
    </div>
</template>

<script>
import { defineComponent } from "vue";

const Switcher = defineComponent({
    name: "Switcher",

    props: {
        data: {
            type: Array,
            default() {
                return [];
            },
        },
        active: {
            type: [String, Number],
            default: 0,
        },
    },

    emits: ["update:active"],

    setup(props, { emit }) {
        const changeActive = (data) => {
            if (props.active !== data) {
                emit("update:active", data);
            }
        };

        return { changeActive };
    },
});
export default Switcher;
</script>

<style lang="scss" scoped>
@import "./switcher";
</style>
