export const document_types = {
    xls: ["xls", "xlsx"],
    pdf: ["pdf"],
    doc: ["docx", "doc"],
    img: ["tif", "tiff", "bmp", "jpg", "jpeg", "gif", "png", "eps", "svg"],
};

export const wide_urls = ["/calendar/index"];

export const snippets = [
    ":title",
    ":firstname",
    ":lastname",
    ":customer",
    ":jobbuilding",
    ":jobstreet",
    ":jobref",
    ":propertyaddress",
    ":contactphone",
    ":companyname",
    ":engineername",
    ":jobregion",
    ":jobpostcode",
    ":jobtown",
];

export const readonlyStatuses = ["READONLY", "NOT_RENEWED", "DORMANT"];

export const currencies = {
    1: "currency_gbp",
    2: "currency_euro",
};

export const graphCurrencies = {
    currency_gbp: "GBP",
    currency_euro: "EUR",
};

export const email_statuses = {
    delivered: "success",
    opened: "success",
    sent: "success",
    failed: "error",
    created: "",
};

export const pageTypes = {
    create: "create",
    edit: "edit",
};

export const photo_extensions = ["tif", "tiff", "bmp", "jpg", "jpeg", "gif", "png", "eps", "svg"];

export const recordLinks = {
    jobno: (id) => `/job/view/${id}`,
    property: (id) => `/property/index/${id}`,
    job_address: (id) => `/property/index/${id}`,
    customer: (id) => `/customer/index/${id}`,
    job_number: (id) => `/job/view/${id}`,
    invoiceno: (id) => `/invoice/edit/${id}`,
    invoices: (id) => `/invoice/edit/${id}`,
    outstanding_invoices: (id) => `/invoice/edit/${id}`,
    paid_invoices: (id) => `/invoice/edit/${id}`,
    job_no: (id) => `/job/view/${id}`,
    jobs: (id) => `/job/view/${id}`,
    job: (id) => `/job/view/${id}`,
};

export const recordIdKeys = {
    jobno: "job_id",
    property: "property_id",
    job_address: "property_id",
    customer: "customer_id",
    invoiceno: "invoice_id",
    job_number: "job_id",
    job_no: "job_id",
    jobs: "job_id",
    job: "job_id",
    invoices: "invoice_id",
    outstanding_invoices: "invoice_id",
    paid_invoices: "invoice_id",
    history: "",
    archived: "",
};
