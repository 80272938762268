<template>
    <div class="page-wrapper custom">
        <PublicHeader />
        <DisconnectBody />
    </div>
</template>
<script>
import { defineComponent } from "vue";

import PublicHeader from "@/widgets/PublicHeader";
import DisconnectBody from "@/widgets/DisconnectBody";

const DisconnectQuickbook = defineComponent({
    name: "DisconnectQuickbook",
    props: {},
    components: { PublicHeader, DisconnectBody },
    setup() {
        return {};
    },
});
export default DisconnectQuickbook;
</script>
<style lang="scss"></style>
