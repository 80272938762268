<template>
    <NotificationWrapper v-slot="slot" :links="links" :info="info" v-if="links">
        <p class="notification__open">
            <Icon icon="bell" />
            <span class="notification__count" v-if="slot?.unseenCount">
                {{ slot.unseenCount < 100 ? slot.unseenCount : "99+" }}
            </span>
        </p>
    </NotificationWrapper>
</template>

<script>
import { defineComponent, inject } from "vue";

import { Icon } from "@/shared/ui";

import NotificationWrapper from "@/entities/Notification";

const NotificationCenterWrapper = defineComponent({
    components: {
        Icon,
        NotificationWrapper,
    },
    setup() {
        const { links, info } = inject("notificationInitData");

        return { links, info };
    },
});

export default NotificationCenterWrapper;
</script>
