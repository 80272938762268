<template>
    <Transition name="appearance">
        <div
            class="custom-loader"
            :class="{
                [`_${size}`]: size,
                _fullscreen: fullscreen,
                _fullsize: fullsize,
                _fixed: fixed,
                _absolute: absolute,
                _background: background,
            }"
        >
            <div class="lds-ring">
                <div></div>
                <div></div>
                <div></div>
                <div></div>
            </div>
        </div>
    </Transition>
</template>

<script>
import { defineComponent, onMounted, onUnmounted } from "vue";

const Loader = defineComponent({
    name: "Loader",
    props: {
        size: {
            type: String,
            default: "default",
        },
        fullscreen: {
            type: Boolean,
        },
        fullsize: {
            type: Boolean,
        },
        fixed: {
            type: Boolean,
        },
        absolute: {
            type: Boolean,
        },
        background: {
            type: Boolean,
        },
    },
    setup(props) {
        const app = document.querySelector("#app");

        onMounted(() => {
            if (props.fullsize) {
                app.classList.add("_hidden-item");
            }
        });

        onUnmounted(() => {
            if (props.fullsize) {
                app.classList.remove("_hidden-item");
            }
        });
    },
});

export default Loader;
</script>

<style lang="scss" scoped>
@import "./loader";
</style>
