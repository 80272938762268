<template>
    <div class="df aic wrap skeleton-photo-managment skeleton-info">
        <div class="skeleton-photo mw30" v-for="i in items || 3" :key="i">
            <div class="photo mb-20" />
            <div class="df jcsb mb-20">
                <h3 class="mw30" />
                <h3 class="mw30" />
            </div>
            <SeparatePage class="mt-16 mb-20" />
            <div class="df">
                <CustomButton text=" " wide :maxWidth="150" />
                <CustomButton text=" " wide class="ml-10" :maxWidth="150" />
            </div>
        </div>
    </div>
</template>

<script>
import { defineComponent } from "vue";

import { SeparatePage } from "../../../SeparatePage";
import { CustomButton } from "../../../CustomButton";

const SkeletonCard = defineComponent({
    name: "SkeletonCard",
    props: {
        items: {
            type: Number,
        },
    },
    components: {
        SeparatePage,
        CustomButton,
    },
});

export default SkeletonCard;
</script>
