/**
 * Calculates the value of a computed cell based on the given row, column, and allColumns.
 *
 * @param {Object} row - The row object containing the data.
 * @param {Object} column - The column object containing the computed property.
 * @param {Array} allColumns - The array of all column objects.
 * @param {boolean} checkVat - Flag indicating whether to check VAT.
 * @return {number} The computed value of the cell, or 0 if the value is not a number.
 */
export const getCellComputed = (row, column, allColumns, checkVat) => {
    if (!column.computed) {
        return row[column.field];
    }

    const regex = /[^a-z ^A-Z]/g;

    const columnVariables = column.computed
        .replace(regex, "")
        .split(" ")
        .filter((col) => col !== "");

    let newComputed = columnVariables.reduce((computed, el) => {
        const column = allColumns.find((col) => col.field === el);
        let isinvisible = typeof column?.invisible === "function" && column?.invisible(row[el]);

        if (typeof isinvisible !== "boolean") {
            isinvisible = !!column?.invisible;
        }

        let isComputed = (!isinvisible || checkVat) && Math.abs(+row[el]) > 0 && !column?.ignore;

        if (isComputed) {
            return computed.replace(el, row[el]);
        } else {
            return computed.replace(el, 0);
        }
    }, column.computed);

    const total = Function(`return ${newComputed}`)();
    if (typeof total === "number") {
        return Number(total).toFixed(2);
    } else {
        return 0;
    }
};
