import { localization } from "@/shared/lib";

export const tabs = [
    {
        key: "create_new",
        component: "CreateSelection",
    },
    {
        key: "select_parents",
        component: "SelectParents",
    },
    {
        key: "certificate",
        component: "FormOrCertificate",
    },
    {
        key: "select_job",
        component: "SelectJob",
    },
    {
        key: "create_record",
        component: "CreateRecord",
    },
    {
        key: "create_job_with_certificate",
        component: "SelectParents",
    },
];

export const buttons = {
    create_new: [],
    select_parents: [
        {
            class: "ml-a",
            transparent: true,
            text: localization("buttons.cancel"),
            type: "prev",
            action: "cancel",
        },
        {
            class: "ml-20",
            text: localization("create_new.records.create"),
            type: "next",
            action: "goToCreateRecord",
            canDisable: true,
        },
    ],
    certificate: [
        {
            class: "ml-a",
            transparent: true,
            text: localization("buttons.cancel"),
            type: "prev",
            action: "cancel",
        },
    ],
    select_job: [
        {
            transparent: true,
            text: localization("create_new.select_job.new"),
            type: "next",
            action: "createCertificateWithJob",
        },
        {
            class: "ml-a",
            transparent: true,
            text: localization("buttons.cancel"),
            type: "prev",
            action: "cancel",
        },
        {
            class: "ml-20",
            text: localization("create_new.create_certificate.next"),
            type: "next",
            action: "createCertificate",
            canDisable: true,
        },
    ],
    create_record: [
        {
            class: "ml-a",
            transparent: true,
            text: localization("buttons.cancel"),
            type: "prev",
            action: "cancel",
        },
        {
            class: "ml-20",
            text: localization("buttons.save"),
            type: "next",
            action: "saveRecord",
            canDisable: true,
        },
    ],
    create_job_with_certificate: [
        {
            class: "ml-a",
            transparent: true,
            text: localization("buttons.cancel"),
            type: "prev",
            action: "cancel",
        },
        {
            class: "ml-20",
            text: localization("buttons.create"),
            type: "next",
            action: "createJobAndCertificate",
            canDisable: true,
        },
    ],
};

export const modalsMeta = {
    create_new: {
        hideButtons: true,
        hiddenBorder: true,
    },
    certificate: {
        disablePadding: true,
        hiddenBorder: true,
    },
    select_job: {
        overflow: "visible",
    },
    create_record: {},
    select_parents: {
        overflow: "visible",
    },
    create_job_with_certificate: {
        overflow: "visible",
    },
};
