<template>
    <div class="skeleton-info">
        <div class="table">
            <div class="table-head">
                <div class="row">
                    <div class="col"><span /></div>
                    <div class="col"><span /></div>
                    <div class="col"><span /></div>
                    <div class="col"><span /></div>
                    <div class="col"><span /></div>
                </div>
            </div>
            <div class="table-body">
                <div class="row">
                    <div class="col"><span /></div>
                    <div class="col"><span /></div>
                    <div class="col"><span /></div>
                    <div class="col"><span /></div>
                    <div class="col"><span /></div>
                </div>
                <div class="row">
                    <div class="col"><span /></div>
                    <div class="col"><span /></div>
                    <div class="col"><span /></div>
                    <div class="col"><span /></div>
                    <div class="col"><span /></div>
                </div>
                <div class="row">
                    <div class="col"><span /></div>
                    <div class="col"><span /></div>
                    <div class="col"><span /></div>
                    <div class="col"><span /></div>
                    <div class="col"><span /></div>
                </div>
                <div class="row">
                    <div class="col"><span /></div>
                    <div class="col"><span /></div>
                    <div class="col"><span /></div>
                    <div class="col"><span /></div>
                    <div class="col"><span /></div>
                </div>
                <div class="row">
                    <div class="col"><span /></div>
                    <div class="col"><span /></div>
                    <div class="col"><span /></div>
                    <div class="col"><span /></div>
                    <div class="col"><span /></div>
                </div>
                <div class="row">
                    <div class="col"><span /></div>
                    <div class="col"><span /></div>
                    <div class="col"><span /></div>
                    <div class="col"><span /></div>
                    <div class="col"><span /></div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { defineComponent } from "vue";

const SkeletonTable = defineComponent({
    name: "SkeletonTable",
});

export default SkeletonTable;
</script>
