import { ref } from "vue";

export function useArchiveModal() {
    const archiveModal = ref({
        visible: false,
        data: {},
    });

    const openArchiveModal = () => {
        archiveModal.value.visible = true;
    };

    const closeArchiveModal = () => {
        archiveModal.value.visible = false;
        archiveModal.value.data = {};
    };

    return {
        archiveModal,
        openArchiveModal,
        closeArchiveModal,
    };
}
