import { deepCopy } from "@/shared/lib";
import { applianceIdKeys, applianceTypesFromTables, backendApplianceTypes } from "../config/constants";
import { api_appliance, useApi } from "@/shared/api";

export const generatePropertyItem = (data) => {
    const { building, street, postcode, property_id } = data;
    const value = `${building ?? ""} ${street ?? ""} ${postcode ?? ""}`.trim();

    return { value, title: value, _val: property_id, disabled: true };
};

export const checkArchivedTypes = (item) => {
    let recordType = item.active;
    let currentData = {};

    if (applianceTypesFromTables[item.active]) {
        recordType = applianceTypesFromTables[item.active];
        const model = backendApplianceTypes[recordType];
        const idKey = applianceIdKeys[recordType];
        currentData.model = model;
        currentData.appliance_id = item.value[idKey];
    }

    return { recordType, currentData };
};

export const setRecord = async (data, recordType) => {
    try {
        const sendData = deepCopy(data);

        for (let key in sendData) {
            sendData[key] = data[key]?.value || data[key];
        }
        sendData.property_id = sendData.property_id ?? data.property?._val;

        const idKey = applianceIdKeys[data.active];

        if (!sendData[idKey] && idKey) {
            sendData[idKey] = "noinsp";
        }

        delete sendData?.property;

        const endpoint = api_appliance.save?.[recordType]?.();
        return await useApi.post_form_data({ link: endpoint, data: sendData });
    } catch (error) {
        console.log(error);
    }
};

export const generateArchiveRecordData = (item, modal) => {
    const { recordType, data } = modal || item;

    const model = backendApplianceTypes[recordType];
    const idKey = applianceIdKeys[recordType];
    const deletionData = {
        data: {
            appliance_id: data[idKey],
            archive: +data.archive,
            model,
        },
        archive: +data.archive,
        page: recordType,
        checkboxes: [],
    };
    return deletionData;
};
