<template>
    <div class="page">
        <div class="custom-container">
            <Card type="blue" small disableShadow class="disconnect__body">
                <div class="disconnect__text">
                    <TextHead :text="i18n.t('integrations.quickbooks.disconnect_title')" class="mb-20" />
                    <p
                        v-html="
                            i18n.t('integrations.quickbooks.disconnect_text', {
                                email: 'support@gasengineersoftware.co.uk ',
                            })
                        "
                    ></p>
                </div>
                <a href="/">
                    <CustomButton :text="i18n.t('auth.login.title')" />
                </a>
            </Card>
        </div>
    </div>
</template>
<script>
import { defineComponent } from "vue";
import { useI18n } from "vue-i18n";

import { Card, CustomButton, TextHead } from "@/shared/ui";

const DisconnectBody = defineComponent({
    name: "DisconnectBody",
    props: {},
    components: { Card, TextHead, CustomButton },
    setup() {
        const i18n = useI18n();
        return { i18n };
    },
});
export default DisconnectBody;
</script>
<style lang="scss">
@import "./disconnect";
</style>
