<template>
    <div
        class="separation"
        :class="{
            'custom-separator': separator,
            '_single-line': !separator,
        }"
    >
        <template v-if="separator">
            {{ i18n.t("element.separator.text") }}
        </template>
    </div>
</template>

<script>
import { defineComponent } from "vue";
import { useI18n } from "vue-i18n";

const SeparatePage = defineComponent({
    name: "SeparatePage",
    props: {
        separator: {
            type: [String],
            default: null,
        },
    },
    setup() {
        const i18n = useI18n();

        return { i18n };
    },
});
export default SeparatePage;
</script>

<style lang="scss" scoped>
@import "./separate-page";
</style>
