<template>
    <div class="skeleton-info">
        <Card type="blue" disableShadow class="pdf-head">
            <div class="pdf-head__wrapper">
                <div class="pdf-head__inner">
                    <h3 class="pdf-head__title mw150px" />
                </div>
                <div class="pdf-head__inner">
                    <div class="pdf-head__flex pdf-head__pages df jcc aic">
                        <CustomButton class="mw-20 mr-10" text=" " />
                        <p>/</p>
                        <CustomButton class="mw-20 mr-10" text=" " />
                    </div>
                    <p class="pdf-head__separate" />
                    <div class="pdf-head__flex">
                        <Icon icon="pdf_minus" />
                        <CustomButton class="mw-20 mr-10" text=" " />
                        <Icon icon="pdf_plus" />
                    </div>
                    <p class="pdf-head__separate" />
                    <div class="pdf-head__flex">
                        <Icon icon="rotate" />
                    </div>
                </div>
                <div class="pdf-head__inner">
                    <CustomButton small transparent class="transparent" icon="print" />
                    <CustomButton small transparent class="transparent" icon="download" />
                </div>
            </div>
        </Card>
    </div>
</template>

<script>
import { defineComponent } from "vue";
import { Card } from "../../../Card";
import { CustomButton } from "../../../CustomButton";
import { Icon } from "../../../Icon";

const SkeletonPdfActions = defineComponent({
    name: "SkeletonPdfActions",
    props: {
        items: {
            type: Number,
            default: 6,
        },
    },
    components: { Card, CustomButton, Icon },
});

export default SkeletonPdfActions;
</script>

<style lang="scss" scoped>
.pdf-head__inner {
    justify-content: flex-start !important;
}
</style>
