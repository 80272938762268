<template>
    <div class="skeleton-info">
        <Card disableShadow small type="blue" class="mb-30 mt-30">
            <div class="df jcsb aic">
                <div class="w100">
                    <CustomButton :key="index" text=" " wide transparent small class="mb-10 mw10" />
                    <CustomButton :key="index" text=" " wide class="mb-10 mw30" />
                    <div class="df mw30">
                        <CustomButton :key="index" text=" " wide class="mb-10 mr-15" />
                        <CustomButton :key="index" text=" " wide class="mb-10" />
                    </div>
                </div>
                <div class="mw30 w100 df jcfe aic">
                    <CustomButton :key="index" text=" " wide transparent class="ml-20 mw10" />
                    <CustomButton :key="index" text=" " wide transparent class="ml-20 mw10" />
                    <CustomButton :key="index" text=" " wide class="ml-20 mw40" />
                </div>
            </div>
        </Card>
    </div>
</template>

<script>
import { defineComponent } from "vue";
import { Card } from "../../../Card";
import { CustomButton } from "../../../CustomButton";

const SkeletonCertificateActions = defineComponent({
    name: "SkeletonCertificateActions",
    props: {
        items: {
            type: Number,
            default: 6,
        },
    },
    components: { Card, CustomButton },
});

export default SkeletonCertificateActions;
</script>
