import { reactive } from "vue";

import { deepCopy, standartizeLink } from "@/shared/lib";
import { applianceTypesFromTables } from "../constants";
import { generateArchiveRecordData, checkArchivedTypes, generatePropertyItem, setRecord } from "../../methods";
import { api_appliance, useApi } from "@/shared/api";
import { pageTypes } from "@/shared/config";
import { useArchiveModal } from "@/shared/config/composables/archiveModal";
import { useNotificationsStore } from "@/shared/model";

export function useAppliance(recordRef, data) {
    const { archiveModal, openArchiveModal, closeArchiveModal } = useArchiveModal();

    const modal = reactive({
        recordType: "",
        pageType: "",
        data: {},
        visible: false,
    });

    const openModal = async ({ value, item = {} }) => {
        modal.recordType = value;
        const { appliance_id, oilappliance_id } = item;
        const id = appliance_id || oilappliance_id;
        if (id) {
            modal.loading = true;
            useApi.get({ link: api_appliance.get?.[value]?.(id) }).then((res) => {
                modal.data = Object.assign(modal.data, res);
                modal.loading = false;
            });
        }
        modal.data.property = generatePropertyItem(data.value);
        modal.pageType = id ? pageTypes.edit : pageTypes.create;
        modal.visible = true;
    };

    const closeModal = () => {
        modal.recordType = "";
        modal.data = {};
        modal.pageType = "";
        modal.visible = false;
    };

    const openDeleteModal = (data = {}) => {
        archiveModal.value.data = data.data ?? {};
        archiveModal.value.page = data.page;
        archiveModal.value.archive = +data.archive;
        archiveModal.value.checkboxes = data.checkboxes;
        openArchiveModal();
    };

    const archiveAppliance = (item) => {
        const deletionData = generateArchiveRecordData(item, modal);
        openDeleteModal(deletionData);
    };

    const setApplianceRecord = async () => {
        try {
            modal.loading = true;
            const { data, recordType } = modal;
            const res = await setRecord(data, recordType);

            if (res.success) {
                closeModal();
                recordRef.value.reloadTable();
            } else if (res.message) {
                useNotificationsStore().addNotification({
                    body: res.message,
                    type: "error",
                });
            }
        } catch (error) {
            console.log(error);
        } finally {
            modal.loading = false;
        }
    };

    const openArchiveFromTable = (item) => {
        let archive = 0;
        const { recordType, currentData } = checkArchivedTypes(item);

        if (item.active === "archived") {
            archive = 1;
        }

        if (!!+item.value.job_archive || !item.value.link) {
            openDeleteModal({
                data: {
                    id: item.value.job_id,
                    ...deepCopy(currentData),
                    archive: +archive,
                },
                archive: +archive,
                page: recordType,
                checkboxes: [],
            });
        } else {
            window.location.href = standartizeLink(item.value.link);
        }
    };

    const clickCol = (col) => {
        const value = applianceTypesFromTables[col.value.active];
        if (value) {
            openModal({ item: col.value.row, value });
        }
    };

    return {
        modal,
        archiveModal,
        openModal,
        closeModal,
        openDeleteModal,
        archiveAppliance,
        setApplianceRecord,
        openArchiveFromTable,
        clickCol,
        closeArchiveModal,
    };
}
