<template>
    <div class="skeleton-info" :class="{ _small: small }">
        <Card type="grey" disableShadow :small="small">
            <div
                :class="{
                    df: small,
                    jcsb: small,
                    aic: small,
                }"
            >
                <div class="big-icon" :class="{ 'ml-a': !small, 'mr-a': !small }" />
                <h3 class="ml-a mr-a" :class="{ 'ml-10': small }" />
                <p class="ml-a mr-a mw30" v-if="!small" />
            </div>
        </Card>
    </div>
</template>

<script>
import { defineComponent } from "vue";
import { Card } from "../../../Card";

const DragAndDrop = defineComponent({
    name: "DragAndDrop",
    props: {
        small: {
            type: Boolean,
        },
    },
    components: { Card },
});

export default DragAndDrop;
</script>
