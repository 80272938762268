const NOTIFICATION_CENTER_TOKEN_KEY = "widget_user_auth_token";
const isBrowser = typeof window !== "undefined";

let headers = {};

const updateHeaders = (data) => {
    if (data) {
        headers = {
            Authorization: `Bearer ${data}`,
        };
    } else {
        headers = {};
    }
};

export const getToken = () => {
    if (isBrowser) {
        const token = localStorage.getItem(NOTIFICATION_CENTER_TOKEN_KEY);
        updateHeaders(token);
        return headers;
    }

    return null;
};

export const removeToken = () => {
    isBrowser && localStorage.removeItem(NOTIFICATION_CENTER_TOKEN_KEY);
    updateHeaders(null);
};

export const setToken = (token = getToken()) => {
    if (token) {
        isBrowser && localStorage?.setItem?.(NOTIFICATION_CENTER_TOKEN_KEY, token);
        updateHeaders(token);
    } else {
        removeToken();
    }
    return headers;
};

export const getTimeAgo = (date, times) => {
    const delta = Math.abs(new Date(date) - Date.now()) / 1000;

    let result;
    Object.keys(times).some((key) => {
        const diff = Math.floor(delta / times[key]);
        if (diff > 0) {
            result = { name: key, value: diff };
            return true;
        }
    });

    return result;
};
