<template>
    <div>
        <TextHead as="h3" :text="title" v-if="!headless && title" />
        <div class="custom-input-switch custom-input-switch__wrapper">
            <SwitchItem
                v-model:active="active"
                v-for="item of items"
                :title="item.title ?? item[keyName]"
                :value="item[keyName] ?? item.title"
                :type="type"
                :key="item.value"
                :icon="item.icon"
            />
        </div>
    </div>
</template>

<script>
import { ref, defineComponent, watch, watchEffect, defineAsyncComponent } from "vue";

import { TextHead } from "../TextHead";

const SwitchItem = defineAsyncComponent(() => import("./overview/SwitchItem.vue"));

const InputSwitch = defineComponent({
    name: "InputSwitch",
    props: {
        title: {
            type: String,
            default: "",
        },
        data: {
            type: String,
            default: "",
        },
        items: {
            type: Array,
            default() {
                return [];
            },
        },
        headless: {
            type: Boolean,
            default: false,
        },
        keyName: {
            type: String,
            default: "value",
        },
        type: {
            type: String,
        },
    },
    emits: ["update:data"],
    components: {
        TextHead,
        SwitchItem,
    },
    setup(props, { emit }) {
        const active = ref(props.data);

        watch(active, () => {
            if (active.value !== props.data) {
                emit("update:data", active.value);
            }
        });

        watchEffect(() => {
            if (props.data !== active.value) {
                active.value = props.data;
            }
        });

        return { active };
    },
});
export default InputSwitch;
</script>

<style lang="scss">
.custom {
    @import "./input-switch";
}
</style>
