<template>
    <div
        class="col"
        :class="{
            [`sm-${sm}`]: sm,
            [`md-${md}`]: md,
            [`lg-${lg}`]: lg,
            [`xl-${xl}`]: xl,
            [`xxl-${xxl}`]: xxl,
        }"
    >
        <slot />
    </div>
</template>
<script>
import { defineComponent } from "vue";

const Col = defineComponent({
    name: "Col",
    props: {
        sm: { type: Number, default: 0 },
        md: { type: Number, default: 0 },
        lg: { type: Number, default: 0 },
        xl: { type: Number, default: 0 },
        xxl: { type: Number, default: 0 },
    },
});

export default Col;
</script>

<style lang="scss" scoped>
@import "./col";
</style>
