<template>
    <div
        class="popover"
        :class="{
            _clickable: clickable,
            _visible: visible,
            [`_${type}`]: type,
        }"
        @mouseenter="changeVisible({ action: true, type: 'hover' })"
        @mouseleave="changeVisible({ action: false, type: 'hover' })"
        v-click-outside="() => changeVisible({ action: false, type: 'click' })"
    >
        <teleport to="#teleport-popover-container" :disabled="!teleportable || !visible">
            <transition name="appearance">
                <div
                    class="popover__body"
                    ref="body"
                    :class="{
                        [`_${position}`]: position,
                        [bodyClass]: bodyClass,
                    }"
                    :style="{
                        top: teleportable && bodyPosition.top,
                        left: teleportable && bodyPosition.left,
                    }"
                    v-if="visible"
                    @click="bodyClick"
                >
                    <slot name="body">
                        {{ text }}
                    </slot>
                </div>
            </transition>
        </teleport>
        <div class="popover__title" @click="changeVisible({ action: !visible, type: 'click' })" ref="title">
            <slot></slot>
        </div>
    </div>
</template>

<script>
import { defineComponent, ref, watch } from "vue";

import vClickOutside from "click-outside-vue3";

const Popover = defineComponent({
    name: "Popover",
    props: {
        text: {
            type: String,
        },
        position: {
            type: String,
            default: "top",
        },
        clickable: {
            type: Boolean,
        },
        teleportable: {
            type: Boolean,
        },
        bodyClass: {
            type: String,
        },
        bodyClickDisable: {
            type: Boolean,
        },
        type: {
            type: String,
        },
    },
    directives: {
        clickOutside: vClickOutside.directive,
    },
    setup(props) {
        const visible = ref(false);

        const title = ref("title");
        const body = ref("body");
        const bodyPosition = ref({
            top: "0%",
            left: "0%",
        });

        const changeVisible = ({ action, type }) => {
            if (!props.clickable && type === "hover") {
                visible.value = action;
            } else if (props.clickable && type === "click") {
                visible.value = action;
            }
        };

        const bodyClick = () => {
            if (props.bodyClickDisable) {
                visible.value = false;
            }
        };

        watch(visible, () => {
            if (props.teleportable) {
                const sizeTitle = title.value?.getBoundingClientRect();

                bodyPosition.value.top = Math.ceil(sizeTitle.top + sizeTitle.height) + 5 + "px";
                bodyPosition.value.left = Math.ceil(sizeTitle.left + sizeTitle.width / 2) + "px";
            }
        });

        return { visible, title, body, bodyPosition, changeVisible, bodyClick };
    },
});
export default Popover;
</script>

<style lang="scss" scoped>
@import "./popover";
</style>
