<template>
    <div
        class="custom-input-checkbox"
        @click="changeElement"
        :class="{
            '_text-click': !ignoreTextClick,
            [`_${color}`]: color,
            _checked: data,
        }"
    >
        <div class="custom-input-checkbox__field" />
        <p class="custom-input-checkbox__text" v-if="text">
            <slot name="text">
                <span v-html="text" />
            </slot>
        </p>
    </div>
    <transition name="appearance">
        <p class="custom-input__error" v-if="error">
            {{ i18n.t(`auth.form.error`) }}
        </p>
    </transition>
</template>

<script>
import { defineComponent } from "vue";

import { useI18n } from "vue-i18n";

const InputCheckbox = defineComponent({
    name: "InputCheckbox",
    props: {
        data: {
            type: Boolean,
        },
        text: {
            type: String,
            default: "",
        },
        error: {
            type: String,
            default: "",
        },
        ignoreTextClick: {
            type: Boolean,
        },
        color: {
            type: String,
            default: "secondary",
        },
    },
    emits: ["update:data", "update:error"],
    setup(props, { emit }) {
        const i18n = useI18n();

        const changeElement = (data) => {
            if (props.ignoreTextClick && !data.target.className.includes("custom-input-checkbox__field")) {
                return;
            }
            emit("update:data", !props.data);
            emit("update:error", "");
        };

        return { i18n, changeElement };
    },
});

export default InputCheckbox;
</script>

<style lang="scss" scoped>
@import "./input-checkbox";
</style>
