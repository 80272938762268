<template>
    <div class="customer">
        <RecordsComponent
            v-model:data="data"
            v-model:error="errors"
            v-model:archiveModal="archiveModal"
            v-model:loading="isLoading"
            showTable
            :id="currentId"
            :pageType="pageType"
            :template="template"
            :tableEndpoints="tableEndpoints"
            :isArchived="isArchived"
            @save="saveRecord"
            @delete="openArchiveModal"
            @setAddress="setAddress"
            @reloadData="getRecord"
            @updateLoading="updateLoading"
        />
    </div>
</template>

<script>
import { defineComponent, provide } from "vue";

import { useRecord } from "@/shared/config";
import RecordsComponent from "@/features/RecordsComponent";

const CustomersPage = defineComponent({
    name: "CustomersPage",
    props: {},
    components: { RecordsComponent },
    setup() {
        const {
            data,
            pageType,
            currentId,
            errors,
            template,
            isLoading,
            tableEndpoints,
            archiveModal,
            isArchived,
            itemsByTypes,
            openArchiveModal,
            saveRecord,
            setAddress,
            getRecord,
            updateLoading,
        } = useRecord("customer");

        provide("itemsByTypes", itemsByTypes);

        return {
            data,
            pageType,
            tableEndpoints,
            currentId,
            errors,
            template,
            isLoading,
            archiveModal,
            isArchived,
            openArchiveModal,
            saveRecord,
            setAddress,
            getRecord,
            updateLoading,
        };
    },
});
export default CustomersPage;
</script>
