<template>
    <div class="notification-loader">
        <img :src="require('./loader.svg')" />
    </div>
</template>

<script>
import { defineComponent } from "vue";

const NotificationLoader = defineComponent({
    name: "NotificationLoader",
});
export default NotificationLoader;
</script>
