import { computed, onMounted, reactive, ref, shallowRef, watch } from "vue";
import { useApiRecord } from "./useApiRecord";
import { useErrors } from "../error/useErrors";
import { useLoading } from "../loading/useLoading";

import { generateTemplate, getItemId, parseQuery, standartizeLink } from "@/shared/lib";
import { pageTypes } from "../../constants";
import { useArchiveModal } from "../archiveModal";
import { showError, useApi } from "@/shared/api";
import * as templates from "../../templates";
import { api_quote_accept } from "@/pages/Jobs/api";

export function useRecord(recordType, keys, id) {
    const recordRef = ref();
    const recordTemplate = templates[recordType];
    const data = ref(recordTemplate.start_data);
    const itemsByTypes = ref({});
    const tableData = shallowRef({});
    const changeParentModal = reactive({
        visible: false,
    });
    const currentId = computed(() => id || getItemId() || data.value?.[`${recordType}_id`] || "");

    const pageType = computed(() => {
        const { create, edit } = pageTypes;
        return currentId.value ? edit : create;
    });

    const template = ref({});
    const isArchived = computed(() => !!+data.value?.archive || !!+data.value.invoiced);
    const { errors, checkErrors, countErrors } = useErrors(template.value, data);
    const { isLoading, startLoading, endLoading, updateLoading } = useLoading();
    const { archiveModal, openArchiveModal, closeArchiveModal } = useArchiveModal();
    const { saveRecordApi, getRecordApi, copyAddressApi, tableEndpoints } = useApiRecord(recordType);

    const cancelCreate = () => {
        window.history.go(-1);
    };

    const setAddress = (address) => {
        data.value = { ...data.value, ...address.value };
    };

    const saveRecord = async () => {
        try {
            startLoading();
            checkErrors();
            if (countErrors.value > 0) {
                throw new Error(JSON.stringify(errors.value));
            }
            await saveRecordApi(data.value, currentId.value, pageType.value);
        } catch (error) {
            showError(error);
        } finally {
            endLoading();
        }
    };

    const getRecord = async () => {
        let query = parseQuery(false, true);
        try {
            startLoading();
            const { resData, resItemsByTypes } = await getRecordApi(
                query,
                currentId.value,
                keys,
                recordTemplate.start_data
            );

            data.value = resData || {};
            itemsByTypes.value = resItemsByTypes;
        } catch (error) {
            showError(error);
        } finally {
            endLoading();
        }
    };

    const copyAddress = async (action) => {
        try {
            startLoading();
            const currentData = await copyAddressApi(action, data.value);
            if (currentData) {
                data.value = Object.assign(data.value, currentData);
            }
        } catch (error) {
            showError(error);
        } finally {
            endLoading();
        }
    };

    const openChangeParent = () => {
        changeParentModal.visible = true;
    };

    const clickCol = ({ value }) => {
        if (value.link) {
            window.location.href = standartizeLink(value.link);
        }
    };

    const acceptQuote = async () => {
        try {
            startLoading();
            await useApi.post({ link: api_quote_accept(currentId.value) });
            endLoading();
            getRecord();
        } catch (err) {
            console.log(err);
        }
    };

    watch(
        () => [data.value, recordTemplate.ignored_columns, recordTemplate, pageType.value, currentId.value],
        () => {
            template.value = generateTemplate(
                recordTemplate,
                pageType.value,
                currentId.value,
                recordTemplate.ignored_columns,
                data.value?.archive,
                true,
                data.value
            );
        },
        { deep: true, immediate: true }
    );

    onMounted(async () => {
        await getRecord();
    });

    return {
        data,
        pageType,
        currentId,
        errors,
        template,
        isLoading,
        archiveModal,
        isArchived,
        itemsByTypes,
        tableData,
        recordRef,
        changeParentModal,
        tableEndpoints,
        openArchiveModal,
        closeArchiveModal,
        saveRecord,
        cancelCreate,
        setAddress,
        getRecord,
        copyAddress,
        updateLoading,
        openChangeParent,
        clickCol,
        acceptQuote,
    };
}
