import en from "../locales/en-GB.json";

/**
 * Retrieves the localized text for the given data by traversing the en.messages object.
 *
 * @param {string} data - The dot-separated path to the desired text in the en.messages object.
 * @return {string} The localized text, or an empty string if the path is invalid or leads to an object.
 */
export const localization = (data) => {
    let text = en.messages;

    data.split(".").forEach((el) => {
        if (text[el]) {
            text = text[el];
        }
    });

    return typeof text == "object" ? "" : text;
};
