const txt = document.createElement("textarea");

/**
 * Decodes an HTML entity in the given string.
 *
 * @param {string} str - The string to decode.
 * @return {string} The decoded string, or an empty string if the input is falsy or contains "<script".
 */
export const decodeHtmlEntity = (str) => {
    if (str && !str.includes("<script")) {
        txt.innerHTML = str;
        return txt.value;
    }
    return "";
};

/**
 * Sets the image links in the given text by replacing the src attribute of each image element with the corresponding image URL from the images array.
 *
 * @param {string} text - The text containing the image elements.
 * @param {Array} images - The array of images containing the filename and URL.
 * @return {string} The modified HTML string with the image links set.
 */
export const setImageLinks = (text, images) => {
    const div = document.createElement("div");

    div.innerHTML = parseEmailContent(text);

    const imageElements = div.querySelectorAll("img");

    imageElements.forEach((element) => {
        const finded = images.find((image) => element.src == `static:${image?.filename}`);

        if (!finded) {
            element.remove();
            return;
        }

        element.src = finded?.url;
    });

    return div.innerHTML;
};

/**
 * Sets the image names in the given text by replacing the src attribute of each image element with the corresponding image filename from the images array.
 *
 * @param {string} text - The text containing the image elements.
 * @param {Array} images - The array of images containing the filename and URL.
 * @return {string} The modified HTML string with the image names set.
 */
export const setImageNames = (text, images) => {
    const div = document.createElement("div");

    div.innerHTML = text;

    const imageElements = div.querySelectorAll("img");

    const tableRows = div.querySelectorAll("th p,td p");

    tableRows.forEach((el) => {
        if (!el.innerHTML) {
            el.innerHTML = "&nbsp;";
        }
    });

    imageElements.forEach((element) => {
        const finded = images.find((image) => element.src === image?.url);

        if (!finded) {
            element.remove();
            return;
        }

        element.src = `static:${finded?.filename}`;
    });

    return div.innerHTML;
};

/**
 * Parses the email content by removing any leading "<p>" and trailing "</p>" tags,
 * replacing newlines with "<br>" tags, and wrapping each line in "<p>" tags.
 *
 * @param {string} data - The email content to parse.
 * @return {string} The parsed email content.
 */
export const parseEmailContent = (data) => {
    if (data.substring(0, 3) === "<p>") {
        data = data.substring(3);
    }
    const { length } = data;

    if (data.substring(length - 4) === "</p>") {
        data = data.substring(0, length - 4);
    }

    data = data.split("\n").join("<br>");
    data = data
        .split(/<br>|<br \/>|<br\/>/g)
        .map((el) => {
            return !!el ? `<p>${el}</p>` : "";
        })
        .join("");

    return data;
};
