<template>
    <div class="custom-input-radio" :class="{ [`_${type}`]: type }" @click="changeValue">
        <p v-if="title">{{ title }}</p>
        <div class="toggler" :class="{ _active: !!currentValue }" />
    </div>
</template>

<script>
import { computed, defineComponent } from "vue";

const InputRadio = defineComponent({
    name: "InputRadio",
    props: {
        title: {
            type: String,
            default: "",
        },
        type: {
            type: String,
            default: "standard",
        },

        data: {
            type: [Boolean, String, Number],
        },
        disableUpdate: {
            type: Boolean,
        },
        name: {
            type: String,
        },
    },
    emits: ["update:data", "clicked"],
    setup(props, { emit }) {
        const currentValue = computed({
            get() {
                let { data } = props;
                if (typeof data === "string" && !isNaN(+data)) {
                    data = +data;
                }
                return data;
            },
            set(data) {
                if (typeof props.data === "string" && !isNaN(+props.data)) {
                    data = +data;
                }
                emit("clicked", { name: props.name || "", value: data });
                emit("update:data", data);
            },
        });

        const changeValue = () => {
            currentValue.value = !currentValue.value;
        };

        return { currentValue, changeValue };
    },
});

export default InputRadio;
</script>

<style lang="scss" scoped>
@import "./input-radio";
</style>
