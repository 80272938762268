<template>
    <Modal
        v-model:visible="currentVisible"
        :loading="loading"
        :width="500"
        :title="i18n.t('records.accept_quote.title')"
    >
        <p class="mt-16 mb-16">
            {{ i18n.t("records.accept_quote.description") }}
        </p>

        <template #buttons>
            <CustomButton :text="i18n.t('buttons.no')" sticky transparent wide @click="changeVisible" />
            <CustomButton :text="i18n.t('buttons.yes')" wide @click="$emit('acceptQuote')" />
        </template>
    </Modal>
</template>

<script>
import { computed, defineComponent } from "vue";
import { useI18n } from "vue-i18n";

import { CustomButton, Modal } from "@/shared/ui";

const AddressModal = defineComponent({
    name: "AddressModal",
    props: {
        visible: {
            type: Boolean,
        },
        loading: {
            type: Boolean,
        },
    },

    emits: ["update:visible"],

    components: { CustomButton, Modal },

    setup(props, { emit }) {
        const i18n = useI18n();

        const currentVisible = computed({
            get() {
                return props.visible;
            },
            set(data) {
                if (!props.loading) {
                    emit("update:visible", data);
                }
            },
        });

        const changeVisible = () => {
            currentVisible.value = false;
        };

        return { currentVisible, i18n, changeVisible };
    },
});
export default AddressModal;
</script>

<style></style>
