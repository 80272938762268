import { generateEditTemplate, localization } from "@/shared/lib";
import { jobs, property, outstanding_invoices, paid_invoices, history } from "./tables";
import { computed } from "vue";
import {
    addressLookup,
    card,
    fullWidthCol,
    inputSelect,
    inputStandard,
    multipleTag,
    standardCustomButton,
    textArea,
    textHead,
    translucentCustomButton,
    transparentCustomButton,
    whiteCustomButton,
} from "./defaultInputProps";

const standard_form_template = [
    {
        component: {},
        child: [
            {
                component: {
                    ...textHead,
                    title: localization("records.customer.details"),
                    lg: 6,
                },
                child: [
                    {
                        component: {
                            ...inputSelect,
                            ...fullWidthCol,
                            title: localization("records.fields.title"),
                            name: "title",
                            itemsType: "titles",
                        },
                    },
                    {
                        component: {
                            ...inputStandard,
                            ...fullWidthCol,
                            title: localization("records.fields.first_name"),
                            validationType: "first_last_company_name",
                            validation: true,
                            name: "forename",
                        },
                    },
                    {
                        component: {
                            ...inputStandard,
                            ...fullWidthCol,
                            title: localization("records.fields.last_name"),
                            required: true,
                            name: "surname",
                            orRequired: "companyname",
                            orRequiredName: localization("records.fields.company_name"),
                            validationType: "first_last_company_name",
                            validation: true,
                        },
                    },
                    {
                        component: {
                            ...inputStandard,
                            ...fullWidthCol,
                            title: localization("records.fields.company_name"),
                            name: "companyname",
                            required: true,
                            orRequiredName: localization("records.fields.last_name"),
                            orRequired: "surname",
                            validationType: "first_last_company_name",
                            validation: true,
                        },
                    },
                    {
                        component: {
                            ...inputStandard,
                            ...fullWidthCol,
                            title: localization("records.fields.vat_number"),
                            type: "number",
                            name: "vat_number",
                        },
                    },
                ],
            },
            {
                component: {
                    ...textHead,
                    title: localization("records.customer.address_details"),
                    lg: 6,
                },
                child: [
                    {
                        component: {
                            ...addressLookup,
                            ...fullWidthCol,
                            title: localization("records.fields.address_lookup"),
                            name: "address_lookup",
                        },
                    },
                    {
                        component: {
                            ...inputStandard,
                            ...fullWidthCol,
                            title: localization("records.fields.building_or_house"),
                            name: "building",
                        },
                    },
                    {
                        component: {
                            ...inputStandard,
                            ...fullWidthCol,
                            title: localization("records.fields.street_address"),
                            name: "street",
                        },
                    },
                    {
                        component: {
                            ...inputStandard,
                            ...fullWidthCol,
                            title: localization("records.fields.town"),
                            name: "town",
                        },
                    },
                    {
                        component: {
                            ...inputStandard,
                            ...fullWidthCol,
                            title: localization("records.fields.region"),
                            name: "region",
                        },
                    },
                    {
                        component: {
                            ...inputStandard,
                            ...fullWidthCol,
                            title: localization("records.fields.post_code"),
                            name: "postcode",
                        },
                    },
                ],
            },
            {
                component: {
                    ...textHead,
                    title: localization("records.customer.contact_details"),
                },
                child: [
                    {
                        component: {
                            ...inputStandard,
                            title: localization("records.fields.mobile"),
                            name: "mobile",
                        },
                    },
                    {
                        component: {
                            ...inputStandard,
                            title: localization("records.fields.landline"),
                            name: "landline",
                        },
                    },
                    {
                        component: {
                            ...inputStandard,
                            title: localization("records.fields.email"),
                            name: "email",
                            validationType: "email",
                            validation: true,
                        },
                    },
                    {
                        component: {
                            ...multipleTag,
                            title: localization("records.fields.second_email"),
                            name: "email2",
                        },
                    },
                ],
            },
            {
                component: {
                    ...textHead,
                    title: localization("records.fields.notes"),
                },
                child: [
                    {
                        component: {
                            ...textArea,
                            name: "notes",
                        },
                    },
                ],
            },
        ],
    },
];

const create_aside_template = [
    {
        component: { ...card },
        child: [
            {
                component: {
                    ...standardCustomButton,
                    text: localization("buttons.save"),
                    action: "save",
                    required: true,
                },
            },
            {
                component: {
                    ...transparentCustomButton,
                    text: localization("buttons.cancel"),
                    buttonComponent: "a",
                    href: `/customer/index`,
                    action: "cancel",
                },
            },
        ],
    },
    {
        component: {},
        child: [
            {
                component: {
                    componentName: "InputRadio",
                    title: localization("records.fields.automatic_reminders"),
                    name: "reminders_enabled",
                    small: true,
                },
            },
        ],
    },
];

const edit_template = computed({
    get() {
        return (data) => [
            {
                component: {
                    componentName: "Card",
                    small: true,
                },
                child: [
                    {
                        component: {
                            ...standardCustomButton,
                            text: localization("records.button.add_job"),
                            buttonComponent: "a",
                            href: `/job/edit/?cust_id=${data.id}`,
                            icon: "add_page",
                            position: "right",
                            action: "save",
                            required: true,
                            disabled: +data.archive,
                        },
                    },
                    {
                        component: {
                            ...whiteCustomButton,
                            componentName: "CustomButton",
                            text: localization("records.button.add_property"),
                            buttonComponent: "a",
                            href: `/property/edit/?cust_id=${data.id}`,
                            icon: "plus",
                            position: "right",
                            disabled: +data.archive,
                        },
                    },
                ],
            },
            {
                component: {},
                child: [
                    {
                        component: {
                            ...transparentCustomButton,
                            text: localization("buttons.save"),
                            action: "save",
                            disabled: +data.archive,
                            required: true,
                        },
                    },
                    {
                        component: {
                            ...translucentCustomButton,
                            text: +data.archive ? localization("buttons.unarchive") : localization("buttons.delete"),
                            action: { key: "delete", archive: data.archive },
                        },
                    },
                    {
                        component: {
                            componentName: "InputRadio",
                            title: localization("records.fields.automatic_reminders"),
                            name: "reminders_enabled",
                            small: true,
                        },
                    },
                ],
            },
        ];
    },
});

export const custom_aside_template = [
    {
        component: {
            componentName: "div",
            content: "flex-end",
        },
        child: [
            {
                component: {
                    ...standardCustomButton,
                    text: localization("buttons.save"),
                    action: "save",
                    required: true,
                },
            },
            {
                component: {
                    ...transparentCustomButton,
                    text: localization("buttons.cancel"),
                    action: "cancel",
                },
            },
        ],
    },
];

export const tables = [jobs, property, outstanding_invoices, paid_invoices, history];

export const form_template = {
    create: [
        ...standard_form_template,

        //{
        //    component: {
        //        text: localization("records.fields.property_same"),
        //        componentName: "InputCheckbox",
        //        name: "property_same",
        //        color: "third",
        //    },
        //},
    ],
    edit: generateEditTemplate(standard_form_template),
};

export const aside_template = {
    create: create_aside_template,
    edit: edit_template.value,
    custom: custom_aside_template,
};

export const archive_checkboxes = ["cascade", "cascade_job", "cascade_appliance"];

export const type = "customer";

export const start_data = {
    reminders_enabled: 1,
    title: { value: "Mr", title: "Mr" },
    address_lookup: { title: "", value: "" },
};

export const ignored_columns = ["customer"];
