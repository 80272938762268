/**
 * Redirects the user to the appropriate social login or signup page based on the current URL.
 *
 * @param {Event} event - The event object containing the social login or signup details.
 * @return {void} This function does not return anything.
 */
export const goSocial = (event) => {
    let type = "";
    if (window.location.href.includes("login")) {
        type = "loginsocial";
    } else if (window.location.href.includes("signup")) {
        type = "signupsocial";
    }
    if (type) {
        const queryString = Object.keys(event.detail)
            .map(function (key) {
                return key + "=" + event.detail[key];
            })
            .join("&");
        window.location = `/user/${type}/?${queryString}`;
    }
};
