import { ref, computed } from "vue";

export function useLoading() {
    const loading = ref(0);
    const isLoading = computed(() => loading.value > 0);

    const startLoading = () => {
        loading.value++;
    };

    const endLoading = () => {
        if (loading.value > 0) {
            loading.value--;
        }
    };
    const updateLoading = (data) => {
        if (data) {
            startLoading();
        } else {
            endLoading();
        }
    };
    return {
        isLoading,
        startLoading,
        endLoading,
        updateLoading,
    };
}
