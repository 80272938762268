import { localization } from "@/shared/lib";
import { job_history } from "./tables";
import { computed } from "vue";
import {
    inputDate,
    inputSelect,
    inputStandard,
    searchRecord,
    standardCustomButton,
    textArea,
    textHead,
    translucentCustomButton,
    transparentCustomButton,
    whiteCustomButton,
} from "./defaultInputProps";

const standard_form_template = [
    {
        component: {
            title: localization("records.job.description"),
            ...textHead,
        },
        child: [
            {
                component: {
                    ...textArea,
                    rows: 1,
                    name: "description",
                },
            },
        ],
    },
    {
        component: {
            title: localization("records.job.details"),
            ...textHead,
        },
        child: [
            {
                component: {
                    ...textArea,
                    rows: 2,
                    resizible: true,
                    name: "details",
                },
            },
        ],
    },
    {
        component: {
            title: "",
            ...textHead,
        },
        child: [
            {
                component: {
                    ...inputSelect,
                    title: localization("records.fields.priority"),
                    name: "priority",
                    itemsType: "priority_titles",
                    outsideTitle: true,
                    lg: 3,
                    selectPosition: "top",
                    required: true,
                    bold: true,
                },
            },
            {
                component: {
                    ...inputDate,
                    title: localization("records.fields.due_date"),
                    name: "due_date",
                    placeholder: "MM/DD/YYYY",
                    outsideTitle: true,
                    bold: true,
                    lg: 3,
                },
            },
            {
                component: {
                    ...inputSelect,
                    title: localization("records.fields.job_status"),
                    name: "job_status_id",
                    outsideTitle: true,
                    selectPosition: "top",
                    placeholder: "Not Selected",
                    itemsType: "job_statuses",
                    bold: true,
                    lg: 3,
                },
            },
            {
                component: {
                    ...inputStandard,
                    title: localization("records.fields.job_ref"),
                    outsideTitle: true,
                    name: "custref",
                    bold: true,
                    lg: 3,
                },
            },
        ],
    },
];

const create_form_template = [
    {
        component: {
            title: localization("records.fields.customer"),
            ...textHead,
        },
        child: [
            {
                component: {
                    ...searchRecord,
                    title: localization("records.fields.find_or_create_customer"),
                    name: "customer",
                    type: "customer",
                    child: "property",
                },
            },
        ],
    },
    {
        component: {
            title: localization("records.fields.property"),
            ...textHead,
        },
        child: [
            {
                component: {
                    ...searchRecord,
                    title: localization("records.fields.find_or_create_property"),
                    name: "property",
                    type: "property",
                    parent: "customer",
                },
            },
        ],
    },

    ...standard_form_template,
];

const create_aside_template = [
    {
        component: {
            componentName: "Card",
            ultrasmall: true,
        },
        child: [
            {
                component: {
                    ...standardCustomButton,
                    text: localization("buttons.save"),
                    action: "save",
                    required: true,
                },
            },
            {
                component: {
                    ...transparentCustomButton,
                    text: localization("buttons.cancel"),
                    action: "cancel",
                    buttonComponent: "a",
                    href: `/job/index`,
                },
            },
        ],
    },
];

const custom_aside_template = [
    {
        component: {
            componentName: "div",
            content: "flex-end",
        },
        child: [
            {
                component: {
                    ...translucentCustomButton,
                    text: localization("buttons.save"),
                    action: "save",
                    wide: false,
                },
            },
            {
                component: {
                    ...standardCustomButton,
                    text: localization("buttons.cancel"),
                    action: "cancel",
                    wide: false,
                },
            },
        ],
    },
];

const edit_aside_template = computed({
    get() {
        return (data) => [
            {
                component: {
                    componentName: "Card",
                    ultrasmall: true,
                },
                child: [
                    {
                        component: {
                            ...standardCustomButton,
                            buttonComponent: "a",
                            href: `/calendar/index?job_id=${data.id}`,
                            text: localization("records.button.add_appointment"),
                            icon: "calendar",
                            disabled: !!+data.archive || !!+data.invoiced,
                            position: "right",
                        },
                    },
                    {
                        component: {
                            ...whiteCustomButton,
                            text: localization("records.button.create"),
                            action: "create",
                            icon: "plus",
                            disabled: !!+data.archive || !!+data.invoiced,
                            position: "right",
                        },
                    },
                    {
                        component: {
                            ...whiteCustomButton,
                            buttonComponent: "a",
                            href: `/job/photos/${data.id}`,
                            text: localization("records.button.photos"),
                            disabled: !!+data.archive || !!+data.invoiced,
                            icon: "photos",
                            position: "right",
                        },
                    },
                    {
                        component: {
                            ...whiteCustomButton,
                            text: localization("records.button.accept_quote"),
                            icon: "acceptQuote",
                            action: "acceptQuote",
                            position: "right",
                            disabled: !!+data.archive || !!+data.invoiced,
                            isVisible: () => data.meta?.can_quote_accept,
                        },
                    },
                    {
                        component: {
                            ...whiteCustomButton,
                            buttonComponent: "a",
                            href: `${data.payment_link}`,
                            text: localization("records.button.add_payment"),
                            disabled: !!+data.archive || !!+data.invoiced,
                            isVisible: () => !!data.payment_link,
                        },
                    },
                ],
            },
            {
                component: {
                    componentName: "div",
                },
                child: [
                    {
                        component: {
                            ...transparentCustomButton,
                            text: localization("buttons.save"),
                            action: "save",
                            disabled: !!+data.archive || !!+data.invoiced,
                        },
                    },
                    {
                        component: {
                            ...translucentCustomButton,
                            text: +data.archive ? localization("buttons.unarchive") : localization("buttons.delete"),
                            action: { key: "delete", archive: data.archive },
                        },
                    },
                ],
            },
        ];
    },
});

export const tables = [job_history];

export const aside_template = {
    create: create_aside_template,
    edit: edit_aside_template.value,
    custom: custom_aside_template,
};

export const form_template = {
    create: create_form_template,
    edit: standard_form_template,
};

export const archive_checkboxes = [];

export const type = "job";

export const itemsKeys = { value: "job_status_id" };

export const start_data = {
    priority: {
        value: 2,
        title: "Normal",
    },
};
export const ignored_columns = [];
