<template>
    <div class="skeleton-info">
        <Card disableShadow small class="mb-20 w100">
            <CustomButton
                v-for="index of items"
                :key="index"
                text=" "
                wide
                :transparent="index !== 1"
                big
                class="mb-10"
            />
        </Card>
        <CustomButton
            v-for="index of 2"
            :key="index"
            text=" "
            wide
            :transparent="index !== 1"
            big
            class="mt-10 mb-10"
        />
        <h3 class="w100" />
        <h3 class="w100" />
    </div>
</template>

<script>
import { defineComponent } from "vue";
import { Card } from "../../../Card";
import { CustomButton } from "../../../CustomButton";

const SkeletonCertificateActions = defineComponent({
    name: "SkeletonCertificateActions",
    props: {
        items: {
            type: Number,
            default: 6,
        },
    },
    components: { Card, CustomButton },
});

export default SkeletonCertificateActions;
</script>
