import SkeletonCard from "./Card.vue";
import SkeletonCertificate from "./Certificate.vue";
import SkeletonCertificateActions from "./CertificateActions.vue";
import SkeletonTable from "./Table.vue";
import SkeletonDashboard from "./Dashboard.vue";
import SkeletonDashboardDonut from "./DashboardDonut.vue";
import SkeletonCertificatePage from "./CertificatePage";
import SkeletonSubscriptions from "./Subscriptions";
import SkeletonGridGallery from "./GridGallery";
import SkeletonDragAndDrop from "./DragAndDrop";
import SkeletonRecordsComponent from "./RecordsComponent";
import SkeletonPublicActions from "./PublicActions";
import SkeletonPdfActions from "./PdfActions";
import SkeletonPdfViewer from "./PdfViewer";
import SkeletonCertificatePayments from "./CertificatePayments";
import SkeletonEmailEditor from "./EmailEditor";

export {
    SkeletonCard,
    SkeletonCertificate,
    SkeletonCertificateActions,
    SkeletonTable,
    SkeletonDashboard,
    SkeletonDashboardDonut,
    SkeletonCertificatePage,
    SkeletonSubscriptions,
    SkeletonGridGallery,
    SkeletonDragAndDrop,
    SkeletonRecordsComponent,
    SkeletonPublicActions,
    SkeletonPdfActions,
    SkeletonPdfViewer,
    SkeletonCertificatePayments,
    SkeletonEmailEditor,
};
