<template>
    <Row class="grid-gallery" :gap="20" wrap v-if="!loading">
        <Col v-for="(image, index) of currentImages" :key="index" :md="4" :sm="6">
            <GalleryItem
                v-model:image="currentImages[index]"
                @deleteItem="openDelete($event, index)"
                @openGallery="openGallery"
            />
        </Col>
    </Row>
    <SkeletonPage type="GridGallery" :data="{ items: currentImages?.length }" v-else />
    <DeleteModal
        v-model:visible="modals.delete.visible"
        :data="modals.delete.data"
        :description="i18n.t('gallery.delete_modal.description')"
        @delete="deleteItem"
    />
    <GridCarousel
        v-model:visible="modals.carousel.visible"
        v-model:data="modals.carousel.data"
        v-model:items="currentImages"
        :initialSlide="initialSlide"
    />
</template>
<script>
import { useI18n } from "vue-i18n";
import { computed, defineComponent, reactive, ref } from "vue";

import { Row, Col, SkeletonPage } from "@/shared/ui";
import GalleryItem from "@/features/GalleryItem";
import DeleteModal from "@/entities/DeleteModal";
import GridCarousel from "@/entities/GridCarousel";

const GridGallery = defineComponent({
    name: "GridGallery",
    props: {
        images: {
            type: Array,
        },
        loading: {
            type: Boolean,
        },
    },
    emits: ["deleteItem"],
    components: { GalleryItem, Row, Col, DeleteModal, GridCarousel, SkeletonPage },
    setup(props, { emit }) {
        const i18n = useI18n();
        const modals = reactive({
            delete: {
                visible: false,
                data: {},
            },
            carousel: {
                visible: false,
                data: {},
            },
        });

        const initialSlide = ref(0);

        const currentImages = computed({
            get() {
                return props.images || [];
            },
            set(data) {
                emit("update:images", data);
            },
        });

        const deleteItem = ({ data }) => {
            emit("deleteItem", data);
            modals.delete.visible = false;
        };

        const openDelete = (data, index) => {
            if (data.photo_id) {
                modals.delete.visible = true;
                modals.delete.data = { ...data, index };
            } else {
                deleteItem({ data, index });
            }
        };

        const openGallery = (data) => {
            initialSlide.value = props.images.findIndex(
                (el) => (data.photo_id && el.photo_id === data.photo_id) || (data.index && el.index === data.index)
            );

            modals.carousel.visible = true;
            modals.carousel.data = data;
        };

        return { currentImages, modals, i18n, initialSlide, deleteItem, openDelete, openGallery };
    },
});
export default GridGallery;
</script>

<style lang="scss">
@import "./assets/grid-gallery";
</style>
