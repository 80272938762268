import { useI18n } from "vue-i18n";
import { computed } from "vue";

import { recordIdKeys, recordLinks } from "../../constants";
import { recordEndpoints, useApi, api_property } from "@/shared/api";
import { changeItemsByTypes, deepCopy, parseData } from "@/shared/lib";
import { useNotificationsStore } from "@/shared/model";
import { copy_templates } from "@/shared/config/templates/property";
import { pageTypes } from "@/shared/config";

let notificationStore;

export function useApiRecord(recordType) {
    const i18n = useI18n();
    const endpoint = computed(() => recordEndpoints[`api_${recordType}`]);
    const tableEndpoints = computed(() => endpoint.value.tables);

    const saveRecordApi = async (data, id, pageType) => {
        try {
            const currentData = parseData({ ...data, title: data.title?.title || data.title });
            if (pageType === pageTypes.create) {
                await createRecordApi(currentData);
            } else {
                const res = await useApi.post_form_data({
                    link: endpoint.value.save(id),
                    data: currentData,
                });
                if (res.success || res.data) {
                    notificationStore.addNotification({
                        type: "success",
                        head: i18n.t(`records.fields.${recordType}`),
                        body: i18n.t(`toasts.saved_successfully`),
                    });
                }
            }
        } catch (error) {
            console.log(error);
            return error;
        }
    };

    const createRecordApi = async (data) => {
        try {
            const res = await useApi.post_form_data({ link: endpoint.value.create(), data });
            const link = recordLinks[recordType];
            const idKey = recordIdKeys[recordType];
            const id = res?.data?.[idKey];
            if (id && link) {
                notificationStore.addNotification({
                    type: "success",
                    head: i18n.t(`records.fields.${recordType}`),
                    body: i18n.t(`toasts.created_successfully`),
                });
                window.location.href = link(id);
            }
        } catch (error) {
            console.log(error);
            return error;
        }
    };

    const getRecordApi = async (query, id, itemsByTypesKeys, startData = {}) => {
        if (!notificationStore) {
            notificationStore = useNotificationsStore();
        }

        try {
            const res = await useApi.get({ link: endpoint.value.get(id, query) });
            const resData = res?.data || startData;
            const { customer, property } = res || {};
            Object.entries({ customer, property }).forEach(([key, item]) => {
                if (item) {
                    resData[key] = {
                        disabled: true,
                        title: item.label,
                        description: item.label,
                        value: item.value,
                        id: item._val,
                        item,
                    };
                }
            });

            if (res.meta) {
                resData.meta = res.meta;
            }

            const resItemsByTypes = changeItemsByTypes(res, itemsByTypesKeys);
            return { resData, resItemsByTypes };
        } catch (error) {
            console.log(error);
            return error;
        }
    };

    const copyAddressApi = async (action, data) => {
        action = action.value || action;
        try {
            const id = data?.value?.[action]?.id || data[action]?.id;
            if (id) {
                const res = await useApi.get({ link: api_property.copy_customer_address(id) });

                const currentData = deepCopy(data.value);
                for (let key in res || {}) {
                    if (key !== "success") {
                        const currentKey = copy_templates[key];
                        currentData[currentKey] = res[key];
                    }
                }
                return currentData;
            } else {
                notificationStore.addNotification({
                    head: i18n.t("notifications.error"),
                    body: `${i18n.t("records.fields.customer")} ${i18n.t("notifications.is_not_selected")}`,
                    type: "error",
                });
            }
        } catch (error) {
            console.error(error);
            return error;
        }
    };

    return { saveRecordApi, getRecordApi, copyAddressApi, tableEndpoints };
}
