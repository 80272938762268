<template>
    <div class="skeleton-info">
        <h3 />
        <p />
        <h3 />
        <p />
        <h3 />
        <p />
    </div>
</template>

<script>
import { defineComponent } from "vue";

const SkeletonCard = defineComponent({
    name: "SkeletonCard",
});

export default SkeletonCard;
</script>
