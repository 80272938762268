<template>
    <div>
        <Row :gap="20" class="mt-15">
            <Col :lg="4" :sm="4" v-for="index of items" :key="index">
                <div class="skeleton-card dashboard">
                    <div class="skeleton-info">
                        <CustomCardHeader :data="{ title: ' ', icon: ' ' }" />
                        <div class="donut-graph" />
                        <SeparatePage class="mt-0 mb-10" />
                        <div class="row fdc">
                            <div class="col df jcsb">
                                <h3 />
                                <CustomButton text=" " wide :maxWidth="100" />
                            </div>
                            <div class="col df jcsb">
                                <h3 />
                                <h3 class="ml-20 mw30" />
                            </div>
                            <div class="col df jcsb">
                                <h3 />
                                <h3 class="ml-20 mw30" />
                            </div>
                        </div>
                    </div>
                </div>
            </Col>
        </Row>
    </div>
</template>

<script>
import { defineComponent } from "vue";

import CustomCardHeader from "@/entities/CustomCardHeader";
import { SeparatePage } from "../../../SeparatePage";
import { CustomButton } from "../../../CustomButton";
import { Row } from "../../../Row";
import { Col } from "../../../Col";

const DashboardCard = defineComponent({
    name: "DashboardCard",
    props: {
        items: {
            type: Number,
            default: 3,
        },
    },
    components: {
        CustomCardHeader,
        SeparatePage,
        CustomButton,
        Row,
        Col,
    },
});

export default DashboardCard;
</script>
